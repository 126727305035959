<div class="row mt-4 ">
  <div class="col-4">
    <h3 class="font-weight-bold">Trip Details</h3>
  </div>
  <div class="col-8 text-right">
      <button mat-raised-button color="primary" class="px-4 mx-3 " (click)="goToAddTrip()" 
      *ngIf="user.roleCd==2 || user.roleCd==1 || isAdmin">
        <mat-icon>add</mat-icon>&nbsp;Add Trip
      </button>
  </div>
</div>
<div *ngIf="tripList.length==0" class="text-center">
  <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div class="table-responsive" *ngIf="tripList.length>0">
  <!-- <app-confirm-dialog></app-confirm-dialog> -->
  <table class="table table-hover table-striped">
    <thead class="bg-primary text-light">
      <tr>
        <th>#</th>
        <th>Vehicle Number</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>customer Name</th>
        <th>Customer Address</th>
        <th>Contact Number</th>
        <th *ngIf="user.roleCd==2 || user.roleCd==1 || isAdmin">Actions</th> 
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let trip of tripList ;let i =index;">
        <td>{{i+1}}</td>
        <td>{{trip.vehicleRegNumber}}</td>
        <td>{{trip.startDate | date:'dd/MM/yyyy, h:mm a'}}</td>
        <td>{{trip.endDate | date:'dd/MM/yyyy, h:mm a'}}</td>
        <td>{{trip.customerName}}</td>
        <td>{{trip.customerAddress}}</td>
        <td>{{trip.contactNumber}}</td>
        <td class="align-middle" *ngIf="user.roleCd==2 || user.roleCd==1 || isAdmin">
          <div class="btn-group" role="group">
            <button type="button" (click)="goToUpdateTrip(i)" class="btn btn-primary btn-sm">
              <i class="fa fa-pencil"></i></button>
            <button type="button" (click)="getReportPdf(i)" class="btn btn-primary btn-sm">
              <i class="bi bi-file-pdf-fill"></i></button>
            <button data-toggle="tooltip" data-placement="top" title="Delete" class="btn btn-primary btn-sm"
              (click)="deleteBooking(i)"><i class="fa-solid fa-trash-can"></i></button>   
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>