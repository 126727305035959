<div class="container-fluid">
  <div class="row justify-content-md-end">
    <div class="col-auto my-2">
      <div class="input-group">
        <div class="input-group-prepend mx-auto" style="width:50px">
          <span class="input-group-text  text-center" style="width:50px"><i class="fa fa-search mx-auto"></i></span>
        </div>
        <input (input)="searchVehicle.next($event);" [(ngModel)]="searchKey" type="text" style="height:42px"
          class="w-25" placeholder="Search Vehicle Number..." class="form-control border-1 ">
      </div>
    </div>
    <div class="col-auto">
      <mat-form-field appearance="outline" class="ml-2">
        <mat-label>Filter By Vehicle Type</mat-label>
        <mat-select [formControl]="vehicleTypeCtrl" (ngModelChange)="getOperaterVehicle()" placeholder="Project"
          [multiple]="true">
          <mat-option>
            <ngx-mat-select-search [formControl]="vehicleTypeFilterCtrl" placeholderLabel="Find vehicle type..."
              noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
              toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="isIndeterminate"
              [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleVehicleTypeSelectAll($event)">
              <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let vehicleType of filteredVehicleTypes | async "
            style="border-bottom:1px solid #ced4da !important;" [value]="vehicleType.codesDtlSno">
            {{ vehicleType.cdValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-auto">
      <mat-form-field appearance="outline" class="ml-2">
        <mat-label>Order by Expiry Type</mat-label>
        <mat-select [formControl]="vehicleExpiryCtrl" (ngModelChange)="getOperaterVehicle()" placeholder="Project"
          [multiple]="false">
          <mat-option>
            <ngx-mat-select-search [formControl]="vehicleExpiryFilterCtrl" placeholderLabel="Find expiry type..."
              noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
              toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="isIndeterminate"
              [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleVehicleExpirySelectAll($event)">
              <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let expiry of filteredExpiryTypes | async "
            style="border-bottom:1px solid #ced4da !important;" [value]="expiry.name">
            {{ expiry.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-auto" *ngIf="appUser.roleCd==2 || appUser.roleCd==1 || isAdmin">
      <button mat-raised-button color="primary" class="mt-3" (click)="goToAddNewVehicle()">
        <i class="fa fa-plus mr-2" aria-hidden="true"></i>
        &nbsp;Add Vehicle
      </button>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col">
      1 of 3
    </div>
    <div class="col-md-auto">
      Variable width content
    </div>
    <div class="col col-lg-2">
      3 of 3
    </div>
  </div> -->
</div>

<!-- <div class="row mb-4">
  <div class="col-12 ">
      <div class="w-25 ">
        <div class="input-group">
          <div class="input-group-prepend mx-auto" style="width:50px">
            <span class="input-group-text  text-center" style="width:50px"><i class="fa fa-search mx-auto"></i></span>
          </div>
          <input  (input)="searchVehicle.next($event);" [(ngModel)]="searchKey"  type="text" style="height:42px" class="w-25"
            placeholder="Search Vehicle Name..." class="form-control border-1 ">
        </div>
      </div>
      <mat-form-field appearance="outline" class="search-form-field">
        <mat-select [formControl]="vehicleTypeCtrl" placeholder="Select vehicle..."
          (selectionChange)="getVehicleList()">
          <mat-option>
            <ngx-mat-select-search [formControl]="projectFilterCtrl" placeholderLabel="Find vehicle..."
              noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
              toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="false"
              [toggleAllCheckboxChecked]="false">
              <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let vehicle of filteredVehicleTypes | async"
            style="border-bottom:1px solid #ced4da !important;" [value]="vehicle?.VehicleSno">
            {{vehicle?.vehicleRegNumber}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="search-form-field">
        <mat-select [formControl]="vehicleTypeCtrl" placeholder="Select vehicle..."
          (selectionChange)="getVehicleList()">
          <mat-option>
            <ngx-mat-select-search [formControl]="projectFilterCtrl" placeholderLabel="Find vehicle..."
              noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
              toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="false"
              [toggleAllCheckboxChecked]="false">
              <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let vehicle of filteredVehicleTypes | async"
            style="border-bottom:1px solid #ced4da !important;" [value]="vehicle?.VehicleSno">
            {{vehicle?.vehicleRegNumber}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" class="mt-3 ml-3 " (click)="goToAddNewVehicle()">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i>
          &nbsp;Add Vehicle
      </button>
     
      </div>
  </div> -->

<!-- 
<div class="row mt-4 ">
  <div class="col-md-7 col-sm-12">
  </div>
  <div class="col-md-5 col-sm-12">
    <div class="row">
      <div class="col-md-7 col-sm-12">
        <div class="input-group mb-2">
          <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-search"></i></span></span>
          <input class="form-control" (input)="searchVehicle.next($event);" [(ngModel)]="searchKey" type="text"
            placeholder="Search Vehicle..." />
        </div>
      </div>
      <div class="col-md-5 col-sm-12">
        <div class="btn-group mb-4 border" role="group" aria-label="Basic example">
          <button type="button" class="btn btn-primary" (click)="goToAddNewVehicle()"><i class="fa fa-plus mr-2"></i>Add
            Vehicle</button>
        </div>
      </div>
    </div>
  </div>
</div> -->


<div *ngIf="vehicleList.length==0" class="text-center">
  <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div class="table-responsive" *ngIf="vehicleList.length>0">
  <app-confirm-dialog></app-confirm-dialog>
  <table class="table  table-hover table-striped">
    <thead>
      <tr class="bg-primary border-0 text-light">
        <th>#</th>
        <!-- <th>Vehicle Name</th> -->
        <th>Registration Number</th>
        <th>Vehicle Type</th>
        <th>Odo Meter Reading</th>
        <!-- <th>Kyc Status</th> -->
        <th *ngIf="vehicleExpiryCtrl.value != 'All'">{{vehicleExpiryCtrl.value}}</th>
        <th *ngIf="vehicleExpiryCtrl.value != 'All'">Date</th>
        <th *ngIf="vehicleExpiryCtrl.value == 'FC Expiry' || vehicleExpiryCtrl.value == 'Insurance Expiry' || vehicleExpiryCtrl.value == 'Tax Expiry'">Amount</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of vehicleList ;let i =index;"
        [ngClass]="row.kycStatus =='KYC Verified'?'text-dark':'text-danger'">
        <td>{{(pageIndex*limit)+i+1}}</td>
        <!-- <td>{{row.vehicleName}}</td> -->
        <td>{{row.vehicleRegNumber}}</td>
        <td>{{row.vehicleTypeName}}</td>
        <td>{{row?.vehicleDetails?.odoMeterValue}}</td>
        <!-- <td class="align-middle text-truncate text-light">
          <span *ngIf="row.kycStatus =='KYC Not Verified' && !row.rejectReason "
            class="badge rounded-pill bg-danger p-2  px-3 text-light">Not
            Verified</span>
          <span *ngIf="row.kycStatus =='KYC Verified'" class="badge p-2  px-3 rounded-pill bg-success text-light">Verified</span>
          <span *ngIf="row.kycStatus =='KYC Not Verified'&& row.rejectReason " class="badge p-2  px-3 rounded-pill bg-danger text-light">
            Rejected</span>
        </td> -->
        <!-- <td class="align-middle text-truncate text-light">
          <span *ngIf="row.kycStatus =='KYC Not Verified'"
            class="badge rounded-pill bg-secondary p-2  px-3 text-light">Not
            Verified</span>
          <span *ngIf="row.kycStatus =='KYC Verified'" class="badge p-2  px-3 rounded-pill bg-success text-light">Verified</span>
          <span *ngIf="row.kycStatus !='KYC Not Verified' && row.rejectReason " class="badge p-2  px-3 rounded-pill bg-danger text-light">
            Rejected</span>
        </td> -->
        <td class="align-middle" *ngIf="vehicleExpiryCtrl.value != 'All'">
          <span *ngIf="vehicleExpiryCtrl.value == 'FC Expiry'">{{row.fcExpiryDays ? row.fcExpiryDays : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Insurance Expiry'">{{row.insuranceExpiryDays ?
            row.insuranceExpiryDays : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Tax Expiry'">{{row.taxExpirydays ? row.taxExpirydays : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Permit Expiry'">{{row.permitExpiryDays ? row.permitExpiryDays :
            '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Pollution Expiry'">{{row.pollutionExpiryDays ?
            row.pollutionExpiryDays : '--'}}</span>
          <!-- <span *ngIf="vehicleExpiryCtrl.value == 'Toll Expiry'">{{row.tollExpiryDays ? row.tollExpiryDays :
            '--'}}</span> -->
        </td>

        <td *ngIf="vehicleExpiryCtrl.value != 'All'" class="align-middle">
          <span *ngIf="vehicleExpiryCtrl.value == 'FC Expiry'">{{row.vehicleDetails.fcExpiryDate ?
            (row.vehicleDetails.fcExpiryDate | date:'dd-MM-yyyy') : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Insurance Expiry'">{{row.vehicleDetails.insuranceExpiryDate ?
            (row.vehicleDetails.insuranceExpiryDate | date:'dd-MM-yyyy') : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Tax Expiry'">{{row.vehicleDetails.taxExpiryDate ?
            (row.vehicleDetails.taxExpiryDate | date:'dd-MM-yyyy') : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Permit Expiry'">{{row.vehicleDetails.permitExpiryDate ?
            (row.vehicleDetails.permitExpiryDate | date:'dd-MM-yyyy') : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Pollution Expiry'">{{row.vehicleDetails.pollutionExpiryDate ?
            (row.vehicleDetails.pollutionExpiryDate | date:'dd-MM-yyyy') : '--'}}</span>
        </td>

        <td class="align-middle" *ngIf="vehicleExpiryCtrl.value == 'FC Expiry' || vehicleExpiryCtrl.value == 'Insurance Expiry' || vehicleExpiryCtrl.value == 'Tax Expiry'">
          <span *ngIf="vehicleExpiryCtrl.value == 'FC Expiry'">{{row.vehicleDetails.fcExpiryAmount ? row.vehicleDetails.fcExpiryAmount : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Insurance Expiry'">{{row.vehicleDetails.insuranceExpiryAmount ? row.vehicleDetails.insuranceExpiryAmount : '--'}}</span>
          <span *ngIf="vehicleExpiryCtrl.value == 'Tax Expiry'">{{row.vehicleDetails.taxExpiryAmount ? row.vehicleDetails.taxExpiryAmount : '--'}}</span>
        </td>

        <td class="align-middle">
          <div class="btn-group" role="group">
            <button data-toggle="tooltip" data-placement="top" title="View" class="btn btn-primary btn-sm"
              (click)="gotoviewVehicle(i)"><i class="fa fa-eye"></i></button>
            <button data-toggle="tooltip" data-placement="top" title="Update" class="btn btn-primary btn-sm"
              *ngIf="appUser.roleCd==2 || appUser.roleCd==1 || isAdmin" (click)="goToEditProject(i,'add')"><i
                class="fa fa-pencil"></i></button>
            <button data-toggle="tooltip" data-placement="top" title="Delete" class="btn btn-primary btn-sm"
              *ngIf="appUser.roleCd==2 || appUser.roleCd==1 || isAdmin" (click)="deleteVehicle(i)"><i
                class="fa-solid fa-trash-can"></i></button>
            <!-- <button data-toggle="tooltip" data-placement="top" title="##COPY_BUTTON_TOOL_TIP##"
                class="btn btn-secondary btn-sm" (click)="goToEditProject(i,'copy')"><i
                  class="fa fa-copy"></i></button> -->
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="count">
    <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="itemPerPage"
      (page)="getMoreVehicle($event)" aria-label="Select page" [pageIndex]="pageIndex">
    </mat-paginator>
  </div>
</div>

<ng-template #modalContent>
  <div class="modal-body bg-gray">
    <h6 class="bg-gray text-center" style="padding-bottom: 3px; color:white;    font-size: 14px;
        font-weight: bold;">{{action}}</h6>
    <div class="row">
      <div class="col-12 vertical-tabs">

        <div class="col-12 vertical-tabs bg-white">
          <table class="table table-hover">
            <tr>
              <td width="50%" class="text-right">
                <label class="text-secondary">Vehicle Name</label>
              </td>
              <td width="50%" class="text-left color-blue"><label> {{selectedVehicle?.vehicleName}}</label>
              </td>
            </tr>

            <tr>
              <td width="50%" class="text-right">
                <label class="text-secondary">Vehicle RegNumber</label>
              </td>
              <td width="50%" class="text-left color-blue"><label> {{selectedVehicle?.vehicleRegNumber}}</label>
              </td>
            </tr>

            <tr>
              <td width="50%" class="text-right">
                <label class="text-secondary">Vehicle TypeName</label>
              </td>
              <td width="50%" class="text-left color-blue"><label> {{selectedVehicle?.vehicleTypeName}}</label>
              </td>
            </tr>

            <tr>
              <td width="50%" class="text-right">
                <label class="text-secondary">Kyc Status</label>
              </td>
              <td width="50%" class="text-left color-blue"><label> {{selectedVehicle?.kycStatus}}</label>
              </td>
            </tr>
          </table>
          <div class="col-12 bg-white text-center py-1">
            <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>