<div class="row mt-4 ">
    <div class="col-4">
      <h3 class="font-weight-bold">Driver Details</h3>
    </div>
    <div class="col-8 text-right">
      <div class="btn-group mb-4 border" role="group" aria-label="Basic example">
        <button type="button" class="btn btn-light" (click)="addDriverAttendance()"><i class="fa fa-plus mr-2"></i>
            Add Driver Attendance</button>
      </div>
    </div>
  </div>
  
   
  <div class="input-group mb-2">
    <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-search"></i></span></span>
    <input class="form-control" type="text" placeholder="Search Driver Name..." />
  </div>

  <div class="text-center">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
  </div>
