<form [formGroup]="searchForm">
  <div class=" p-2">
    <div class="row justify-content-end">
      <div class="col-lg-3 col-md-3 col-sm-12 ">
        <div class="form-group dropdown-container">
          <label class="text-gray">Driver Name</label>
          <select formControlName="driverSno" class="form-control fa-select minimal">
            <option [value]=driver.driverSno *ngFor="let driver of driverList;">
              {{driver.driverName}}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-12">
        <div class="form-group dropdown-container">
          <label class="text-gray">Bus Number</label>
          <select formControlName="vehicleSno" class="form-control fa-select minimal">
            <option [value]=org.vehicleSno *ngFor="let org of orgvehicles;">
              {{org.vehicleRegNumber}}</option>
          </select>
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-12">
        <div class="form-group">
          <label class="text-gray">From date</label>
          <div class="input-group">
            <input class="form-control" type="date" placeholder="dd-mm-yy" formControlName="fromDate">
          </div>
        </div>
      </div>

      <div class="col-lg-2 col-md-4 col-sm-12">
        <div class="form-group">
          <label class="text-gray">To date</label>
          <div class="input-group">
            <input class="form-control" type="date" placeholder="To Date" formControlName="toDate">
          </div>
        </div>
      </div>
      <div (click)="goToSearch()" class="col-lg-2 col-md-4 col-sm-12">
        <div class="input-group-text btn btn-primary my-4  px-4"><i class="fa fa-search"></i> &nbsp; Search</div>
      </div>
      <!-- <div class="col-3 btnDiv btn" (click)="goToSearch()">
        <span class="input-group-text btn-sm1"><i class="fa fa-search"></i> &nbsp; Search</span>
      </div> -->
    </div>
  </div>
</form>
<br>
<div *ngIf="driverReportList.length==0" class="text-center">
  <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>
<div class="table-responsive-sm" *ngIf="driverReportList?.length">
  <app-confirm-dialog></app-confirm-dialog>
  <table class="table table-striped table-hover">
    <thead class="bg-primary text-light border-0">
      <tr>
        <th>#</th>
        <th>Driver Name</th>
        <th>Vehicle Number</th>
        <th>Vehicle Type</th>
        <th>Start Date</th>
        <th>End Date</th>
        <th>Drived Km</th>
        <th>Fuel (Li)</th>
        <th>Mileage</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let driver of driverReportList ;let i =index;">
        <!-- {{driver.report[0] | json}} -->
        <td>{{(pageIndex*limit)+i+1}}</td>
        <td>{{driver.driverName}}</td>
        <td>{{driver.vehicleRegNumber}}</td>
        <td>{{driver.drivingType}}</td>
        <td>{{driver.startTime | date:'d/M/yy, h:mm a'}}</td>
        <td>{{driver.endTime | date:'d/M/yy, h:mm a'}}</td>
        <td>{{driver.totalDrivingKm}}</td>
        <td>{{driver.fuelQty | number : '1.2-2'}}</td>
        <td>{{driver.mileage | number : '1.2-2' }}</td>

        <!-- <td>
          <div *ngFor="let data of driver?.report[0];let j =index;" class="p-1">{{data.vehicleType ?? "--"}}</div>
        </td>
        <td>
          <div *ngFor="let data of driver?.report[0];let j =index;" class="p-1">{{data.kms ?? '--'}}</div>
        </td>
        <td>
          <div *ngFor="let data of driver?.report[0];let j =index;" class="p-1">{{data.fuel ?? '--'}}</div>
        </td>
        <td>
          <div *ngFor="let data of driver?.report[0];let j =index;" class="p-1">
            <span *ngIf="!data.mileage">
--
            </span>
          <span>
            {{data.mileage  | number : '1.2-2' }}
          </span>  
          
          </div>
        </td> -->
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="driverReportList.length > 0" class="d-flex justify-content-between">
  <div>
    <button type="button" (click)="getReportPdf()" class="btn btn-light btn-lg border border-primary"><i
        class="bi bi-file-pdf-fill text-danger"></i> Report</button>
  </div>
  <div>
    <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="itemPerPage" [pageIndex]="pageIndex"
      (page)="getMoreDriver($event)" aria-label="Select page">
    </mat-paginator>
  </div>
</div>