<div class="container-fluid">
    <div class="row justify-content-md-end">
      <div class="col-auto my-2">
        <div class="input-group">
          <div class="input-group-prepend mx-auto" style="width:50px">
            <span class="input-group-text  text-center" style="width:50px"><i class="fa fa-search mx-auto"></i></span>
          </div>
          <input (input)="searchVehicle.next($event);" [(ngModel)]="searchKey" type="text" style="height:42px"
            class="w-25" placeholder="Search Vehicle Number..." class="form-control border-1 ">
        </div>
      </div>



      <div class="col-auto">
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>Filter By Status Type</mat-label>
          <mat-select [formControl]="statusCtrl" (ngModelChange)="getOperaterVehicle()" placeholder="Project"
            [multiple]="false">
            <mat-option>
              <ngx-mat-select-search [formControl]="vehicleStatusFilterCtrl" placeholderLabel="Find Status type..."
                noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleVehicleStatusSelectAll($event)">
                <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let statusType of filteredStatusTypes | async "
              style="border-bottom:1px solid #ced4da !important;" [value]="statusType.status">
              {{ statusType.status }}
            </mat-option>
          </mat-select> 
        </mat-form-field>
      </div>

      <div class="col-auto">
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>Filter By Vehicle Type</mat-label>
          <mat-select [formControl]="vehicleTypeCtrl" (ngModelChange)="getOperaterVehicle()" placeholder="Project"
            [multiple]="true">
            <mat-option>
              <ngx-mat-select-search [formControl]="vehicleTypeFilterCtrl" placeholderLabel="Find vehicle type..."
                noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleVehicleTypeSelectAll($event)">
                <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let vehicleType of filteredVehicleTypes | async "
              style="border-bottom:1px solid #ced4da !important;" [value]="vehicleType.codesDtlSno">
              {{ vehicleType.cdValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-auto">
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>Order by Expiry Type</mat-label>
          <mat-select [formControl]="vehicleExpiryCtrl" (ngModelChange)="getOperaterVehicle()" placeholder="Project"
            [multiple]="false">
            <mat-option>
              <ngx-mat-select-search [formControl]="vehicleExpiryFilterCtrl" placeholderLabel="Find expiry type..."
                noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleVehicleExpirySelectAll($event)">
                <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let expiry of filteredExpiryTypes | async "
              style="border-bottom:1px solid #ced4da !important;" [value]="expiry.name">
              {{ expiry.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
<span>
    <div *ngIf="vehicleList.length==0" class="text-center">
        <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
    </div>

    <span *ngIf="vehicleList.length>0">
        <div class="row">
            <div class="col-12">
                <div class="table table-hover table-responsive  table-striped">
                    <table class="table table-hover">
                        <thead class="bg-primary text-light">
                            <tr>
                                <th>#</th>
                                <th>Registration Number</th>
                                <th>Company Name</th>
                                <th>Owner Name</th>
                                <th>Vehicle Type</th>
                                <!-- <th>KYC Status</th> -->
                                <th>Primary Permit District</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of vehicleList ;let i =index;" [ngClass]="row.kycStatus =='KYC Verified'?'text-dark':'text-danger'">
                                <td class="align-middle">{{(pageIndex*limit)+i+1}}</td>
                                <td class="align-middle">{{row.vehicleRegNumber}}</td>
                                <td class="align-middle">{{row.vehicleName}}</td>
                                <td>
                                    <div [class]="row.isShow ? '' : 'd-flex'">
                                        <div *ngFor="let owner of row.ownerList,let j=index">
                                            <div class="w-75 d-flex" *ngIf="row.isShow ? j >= 0 : j <= 1">
                                                <span>{{owner?.ownerName}}</span>
                                                <span>{{ (row?.ownerList?.length - 1 != j) && (row?.isShow ? true : j ==
                                                    0 ) ? ',':''}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn text-primary" *ngIf="row?.ownerList?.length > 2 && !row?.isShow"
                                        (click)="row.isShow = !row.isShow">+{{row?.ownerList?.length - 2}} More</div>
                                    <div class="btn text-primary" *ngIf="row?.isShow"
                                        (click)="row.isShow = !row.isShow">
                                       -{{row?.ownerList?.length - 2}} less
                                    </div>
                                </td>
                                <td class="align-middle">{{row.vehicleTypeName}}</td>
                                <!-- <td class="align-middle text-truncate">
                                    <span *ngIf="row.kycStatus =='KYC Not Verified' && !row.rejectReason "
                                        class="badge rounded-pill bg-danger text-light">Not
                                        Verified</span>
                                    <span *ngIf="row.kycStatus =='KYC Verified'"
                                        class="badge rounded-pill bg-success text-light">Verified</span>
                                    <span *ngIf="row.kycStatus =='KYC Not Verified'&& row.rejectReason "
                                        class="badge rounded-pill bg-danger">
                                        Rejected</span>

                                </td>
                                <td class="align-middle">{{row.vehicleDetails?.districtName}}</td>
                                <td class="align-middle">
                                    <span class="text-success h6" *ngIf="row?.activeFlag == 'true' && row?.kycStatus !='KYC Not Verified'">Running</span>
                                    <span class="text-danger h6" *ngIf="row?.activeFlag == 'false' && row?.kycStatus !='KYC Not Verified'">Out of service</span>
                                    <span class="text-primary h6" *ngIf="row?.kycStatus =='KYC Not Verified'">Not Verified</span>
                                </td> -->
                           
                                <!-- <td class="align-middle text-truncate">
                                    <span *ngIf="row.kycStatus =='KYC Not Verified'"
                                        class="badge rounded-pill bg-secondary text-light">Not
                                        Verified</span>
                                    <span *ngIf="row.kycStatus =='KYC Verified'"
                                        class="badge rounded-pill bg-success text-light">Verified</span>
                                    <span *ngIf="row.kycStatus !='KYC Not Verified'&& row.rejectReason "
                                        class="badge rounded-pill bg-danger text-light">
                                        Rejected</span>

                                </td> -->

                                <td class="align-middle">{{row.vehicleDetails?.districtName}}</td>
                                <td class="align-middle">
                                    <span class="text-success h6"
                                        *ngIf="row?.activeFlag == 'true' && row?.kycStatus !='KYC Not Verified' && row?.kycStatus !='KYC Rejected'">Running</span>
                                    <span class="text-danger h6"
                                    *ngIf="row?.activeFlag == 'false' && row?.kycStatus !='KYC Not Verified' && row?.kycStatus =='KYC Verified'">Out
                                        of service</span>
                                    <span class="text-secondary h6" *ngIf="row?.kycStatus =='KYC Not Verified'">Not
                                        Verified</span>
                                </td>
                                <td class="align-middle">
                                    <div class="btn-group" role="group">
                                        <button *ngIf="row.kycStatus !='KYC Verified'" title="Approve"
                                            data-target="#approve-modal" data-toggle="modal"
                                            class="btn btn-primary btn-sm" (click)="openModal(i)"><i
                                                class="fa fa-check"></i>
                                        </button>
                                        <button title="View row" data-target="#viewModel" data-toggle="modal"
                                            (click)="gotoviewVehicle(i)" class="btn btn-primary btn-sm">
                                            <i class="fa fa-eye"></i>
                                        </button>

                                        <button title="update" data-toggle="tooltip" class="btn btn-primary btn-sm"
                                            (click)="goToEditProject(i,'add')">
                                            <i class="fa fa-pencil"></i>
                                        </button>

                                        <button title="Reject" *ngIf="row.kycStatus !='KYC Verified'"
                                            data-target="#reject-modal" data-toggle="modal" (click)="openModal(i)"
                                            class="btn btn-primary btn-sm">
                                            <i class="fa fa-ban"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="count">
                        <mat-paginator [length]="count" [pageSize]="pageSize"
                            [pageSizeOptions]="itemPerPage" (page)="getMoreVehicle($event)"
                            aria-label="Select page" [pageIndex]="pageIndex">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </span>
</span>



<div class="modal fade" id="reject-modal" role="dialog" tabindex="-1" aria-labelledby="modal-error-label"
    aria-hidden="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-danger">
                <h4 class="modal-title" id="modal-error-label"><i class="fa fa-warning"></i>Reject Reason Operator KYC
                </h4>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <textarea class="form-control" style="height: 100px;" id="pxp-candidate-about"
                    placeholder="Type reason here..." [(ngModel)]="reason"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" [disabled]="!reason" data-dismiss="modal"
                    (click)="changeStatus('Reject')">Yes</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="approve-modal" role="dialog" tabindex="-1" aria-labelledby="modal-approve-label"
    aria-hidden="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-success">
                <h4 class="modal-title" id="modal-approve-label"><i class="fa fa-warning"></i>Approve Operator KYC</h4>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                Are you sure to approve this Vehicle Kyc?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal"
                    (click)="changeStatus('Approve')">Yes</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="viewModel" tabindex="-1" role="dialog" aria-labelledby="viewModelLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content col-12 col-md-12 col-sm-12">
            <div class="modal-header">
                <h4 class="modal-title font-weight-bold" id="viewModelLabel">Vehicle Detail</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body col-12 col-md-12 col-sm-12">
                <!-- <div class="row mb-1 m-0">
                    <div class="col d-flex">
                        <div class="pxp-jobs-tab-pane-category-icon"><span class="fa fa-bus"></span></div>&nbsp;
                        <span>{{selectedVehicle?.vehicleRegNumber}}</span>
                    </div>
                </div> -->

                <div class="container mt-3">
                    <div class="row mb-4 text-center">
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="font-weight-bold mb-2">Vehicle Number</div>
                            <p class="text-capitalize">{{selectedVehicle?.vehicleRegNumber}}</p>
                        </div>
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="font-weight-bold mb-2">Vehicle Name</div>
                            <p>{{selectedVehicle?.vehicleName}}</p>
                        </div>
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="font-weight-bold mb-2">Vehicle Type</div>
                            <div>
                                <span
                                    *ngIf="selectedVehicle?.vehicleTypeName">{{selectedVehicle?.vehicleTypeName}}</span>
                                <span *ngIf="!selectedVehicle?.vehicleTypeName">-</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4" *ngIf="selectedVehicle?.ownerList?.ownerName">
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="mb-2 font-weight-bold">
                                <span class="fa fa-phone" aria-hidden="true"></span> Contact
                            </div>
                            <div *ngFor="let contact of selectedVehicle?.ownerList">
                                <div>Owner Name:&nbsp;<span>{{contact.ownerName}} {{selectedVehicle?.ownerList.length}}
                                    </span>
                                </div>
                                <div>Mobile:&nbsp;<span>{{contact.ownerNumber}}</span></div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4 text-center">
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="mb-2 font-weight-bold">
                                KYC Status
                            </div>
                            <div>
                                {{selectedVehicle?.kycStatus}}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>