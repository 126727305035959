
  <!-- <div class="container p-5">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="card">
          <h2 class="text-center py-4">Bus Mileage Report</h2>
          <div class="text-center py-4">
            <img src="assets/img/bus.png" class="rounded" alt="Bus report" width="10">
          </div>
          <br>
          <div class="py-4">
            <h3 class="text-center">See your all vehicle mileage report here!</h3>
          </div>
        </div>
      </div>
    </div>
  </div> -->


<div class="container">
    <div class="card1__container row">
      <div class=" col-md-4 col-sm-12" (click)="goToBusReport()">
        <div class="card1__content">
          <h2 class="text-center">Bus Mileage Report</h2>
          <div class="imgDiv">
          <img class="text-center" src="assets/img/bus.png">
        </div><br><br>
        <h3  class="text-center">See your all vehicle mileage report here!</h3>
        </div>
      </div>
  
      <div class=" col-md-4 col-sm-12" (click)="goToDriverReport()">
        <div class="card1__content">
          <h2 class="text-center">Driver Mileage Report</h2>
          <div class="imgDiv">
          <img class="text-center" src="assets/img/driver.png">
        </div><br><br>
        <h3  class="text-center">See your all driver mileage report here!</h3>
        </div>
      </div>
  
      <div class=" col-md-4 col-sm-12" (click)="goToFuelReport()">
        <div class="card1__content">
          <h2 class="text-center">Fuel Report</h2>
          <div class="imgDiv">
          <img class="text-center" src="assets/img/fuel.png" width="64px" height="64px">
        </div><br><br>
        <h3 class="text-center">See your vehicle fuel report here!</h3>
        </div>
      </div>
    </div>
  </div>