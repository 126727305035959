<div class="container-fluid">
  <div class="row justify-content-md-end">
    <div class="col-auto my-2">
      <div class="input-group">
        <div class="input-group-prepend mx-auto" style="width:50px">
          <span class="input-group-text  text-center" style="width:50px"><i class="fa fa-search mx-auto"></i></span>
        </div>
        <input (input)="searchVehicle.next($event);" [(ngModel)]="searchKey" type="text" style="height:42px"
          class="w-25" placeholder="Search Vehicle Number..." class="form-control border-1 ">
      </div>
    </div>
  </div>
</div>

<div *ngIf="dueExpiryList.length==0" class="text-center">
  <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div class="table-responsive" *ngIf="dueExpiryList.length>0">
  <form [formGroup]="passPaid">
    <table class="table  table-hover table-striped">
      <thead>
        <tr class="bg-primary border-0 text-light">
          <th>#</th>
          <th>Registration Number</th>
          <th>Vehicle Name</th>
          <th>Loan Type</th>
          <th>Loan Bank Name</th>
          <th>Account Number</th>
          <th>Amount</th>
          <th>Next Emi Date</th>
          <th>Last Emi Date</th>
          <th *ngIf="vehicleExpiryCtrl.value == 'Due Expiry'">Expire Days</th>
          <th *ngIf="vehicleExpiryCtrl.value == 'Due Expiry'">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of dueExpiryList ;let i =index;">
          <td>{{(pageIndex*limit)+i+1}}</td>
          <td>{{row?.vehicleRegNumber}}</td>
          <td>{{row?.vehicleName}}</td>
          <td><span *ngIf="row.dueTypeCd == 129">Fixed</span><span *ngIf="row.dueTypeCd == 130">Variable</span></td>
          <td>{{row?.bankName}}</td>
          <td>{{row?.bankAccountNumber}}</td>
          <td><span *ngIf="vehicleExpiryCtrl.value == 'Due Expiry'">{{row?.variableDueAmount}}</span>
            <span *ngIf="vehicleExpiryCtrl.value == 'No of Emi/Payments'">{{row?.variableDueAmount}}</span></td>
          <td class="align-middle"><span *ngIf="vehicleExpiryCtrl.value == 'Due Expiry'">
            {{row.duePayDate | date:'dd-MM-yyyy'}}</span><span *ngIf="vehicleExpiryCtrl.value == 'No of Emi/Payments'">
              {{row.duePayDate | date:'dd-MM-yyyy'}}</span></td>

          <td>{{row?.dueDate | date:'dd-MM-yyyy'}}</td>
          <td class="align-middle">
            <span *ngIf="vehicleExpiryCtrl.value == 'Due Expiry'">{{row.dueExpiryDays ?
              row.dueExpiryDays :
              '--'}}</span>
          </td>
          <td *ngIf="vehicleExpiryCtrl.value == 'Due Expiry'" class="align-middle">
            <div class="form-group">
              <input class="form-check-input" type="checkbox" (change)="tollPassDetails(i)" [checked]="isPaidToPass"
                formControlName="isPassPaid" value="" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                Paid
              </label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </form>
  <div>
    <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="itemPerPage"
      (page)="getMoreVehicle($event)" aria-label="Select page" [pageIndex]="pageIndex">
    </mat-paginator>
  </div>
</div>