// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyABJorEL7U_EFzSgkvVbf0LDbmC-3xJTso",
    authDomain: "bustoday-b5aca.firebaseapp.com",
    projectId: "bustoday-b5aca",
    storageBucket: "bustoday-b5aca.appspot.com",
    messagingSenderId: "367001029957",
    appId: "1:367001029957:web:229df084300e93086c822c",
    measurementId: "G-PMFCXZ7WYC",
    vapidKey: "BNpA_q2EO6VoRqEpQyKeGOQ7JUWMFnQMvoOhmE3qfNsMOXWtgKqOb59xYsTmTOyPXDP8d_TfvODPFajP6tYqz8o"
  }
};


export const API_URL = "http://localhost:";

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
