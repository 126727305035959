<div class="col-md-12">
  <div class="rounded bg-primary p-3 mt-4">
    <span style="color: rgb(249, 244, 244);">Find a Bus</span>
  </div>
  <div class="card rounded-3 mt-4">
    <!-- <div class="card-header">
        <h1>Find Bus</h1>
      </div> -->
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <div class="d-flex py-2">
            <div class="align-self-center">
              <i class="fa fa-map-marker" style="font-size: 20px;"></i>
            </div>
            <mat-form-field appearance="outline" class="col-lg-11 ml-2">
              <mat-label>Select Source </mat-label>
              <mat-select [formControl]="sourceTypeCtrl" placeholder="Project"
                (selectionChange)="onSourceTypeChange($event)">
                <mat-option>
                  <ngx-mat-select-search [formControl]="sourceTypeFilterCtrl" placeholderLabel="Select Source..."
                    noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="false"
                    toggleAllCheckboxTooltipMessage="Select / Unselect All"
                    [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                    (toggleAll)="toggleSourceTypeSelectAll($event)">
                    <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let sourceType of filteredSourceTypes | async "
                  style="border-bottom:1px solid #ced4da !important;" [value]="sourceType.citySno">
                  {{ sourceType.cityName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="col-md-1">
          <div class="d-flex  mt-4 justify-content-between">
            <div>
              <i class="bi bi-arrow-left-right circle-icon" style="font-size: 20px;" (click)="SwappingSourDest()"></i>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="d-flex py-2">
            <div class="align-self-center">
              <i class="fa fa-map-marker" style="font-size: 20px;"></i>
            </div>
            <mat-form-field appearance="outline" class="col-lg-11 ml-2">
              <mat-label>Select Destination </mat-label>
              <mat-select [formControl]="destinationTypeCtrl" placeholder="Project"
                (selectionChange)="onDestTypeChange($event)">
                <mat-option>
                  <ngx-mat-select-search [formControl]="destinationTypeFilterCtrl"
                    placeholderLabel="Select Destination..." noEntriesFoundLabel="'no matching found'"
                    [showToggleAllCheckbox]="false" toggleAllCheckboxTooltipMessage="Select / Unselect All"
                    [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                    (toggleAll)="toggleDestiTypeSelectAll($event)">
                    <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let destType of filteredDestinationTypes | async "
                  style="border-bottom:1px solid #ced4da !important;" [value]="destType.citySno">
                  {{ destType.cityName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>


        <div class="col-md-3">
          <div class="d-flex py-2">
            <div class="align-self-center">
              <i class="fa fa-map-marker" style="font-size: 20px;"></i>
            </div>

            <mat-form-field appearance="outline" class=" ml-2 col-lg-11">
              <mat-label>Select Via Route </mat-label>
              <mat-select [formControl]="viaTypeCtrl" placeholder="Project" [multiple]="true" (selectionChange)="onViaChange($event)"
                [disabled]="isFormFieldDisabled" >
                <mat-option>
                  <ngx-mat-select-search [formControl]="viaTypeFilterCtrl" placeholderLabel="Select Via Route..."
                    noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="false" 
                    toggleAllCheckboxTooltipMessage="Select / Unselect All"
                    [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                    (toggleAll)="toggleViaTypeSelectAll($event)">
                    <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let viaType of filteredVaiTypes | async "
                  style="border-bottom:1px solid #ced4da !important;" [value]="viaType.viaSno">
                  {{ viaType.viaName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="align-self-end ml-auto mr-3">
          <!-- <button mat-raised-button color="primary" class="mt-3 float-left " [disabled]="!enableButton"
            (click)="getRouteCity()">
              Find Bus
            </button> -->
          <button type="button" class="btn btn-primary mb-1 mr-3" (click)="getRouteCity()"
            [disabled]="!enableButton">Find Bus</button>
        </div>
      </div>
    </div>

  </div>
</div>
<div class="col-md-12">
  <div class="rounded bg-primary p-3 mt-4" *ngIf="cityRouteList.length>0 && show">
    <span *ngIf="cityRouteList.length==0 && !show" style="color: rgb(249, 244, 244);">Find a Bus</span>
    <div class='head-div my-auto' style="color: rgb(249, 244, 244);">{{sourceCityName}} - {{destCityName}}</div>
  </div>
  <div *ngIf="cityRouteList.length==0" class="text-center">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
  </div>

  <div class="table-responsive mt-4" *ngIf="cityRouteList.length>0">
    <!-- <app-confirm-dialog></app-confirm-dialog> -->
    <table class="table  table-hover table-striped">
      <thead>
        <tr class="bg-primary border-0 text-light">
          <th>#</th>
          <th>Vehicle Name</th>
          <th>Registration Number</th>
          <th>Time</th>
          <th>Via Route</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of cityRouteList ;let i =index;">
          <td>{{(pageIndex*limit)+i+1}}</td>
          <td>{{row.vehicleName}}</td>
          <td>{{row.vehicleRegNumber}}</td>
          <td>{{row.startingTime | date:'h:mm a' }}</td>
          <td>{{row.cityName}}</td>
        </tr>
      </tbody>
    </table>
    <!-- <div *ngIf="count">
          <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="itemPerPage"
            (page)="getMoreVehicle($event)" aria-label="Select page" [pageIndex]="pageIndex">
          </mat-paginator>
        </div> -->
    <div *ngIf="cityRouteList.length>0">
      <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="itemPerPage" (page)="getMoreCity($event)"
        aria-label="Select page" [pageIndex]="pageIndex">
      </mat-paginator>
    </div>
  </div>

</div>