<!-- <div class="row"> -->
<div class="col-md-12">
  <div class="rounded bg-primary p-3 mt-4" *ngIf="isNotTripCalculate">
    <span style="color: rgb(249, 244, 244);">Rent a Bus</span>
  </div>
  <div class="card rounded-3 mt-4" *ngIf="isNotTripCalculate">
    <!-- <div class="card-header">
          <h1>Rent Bus</h1>
        </div> -->
    <div class="card-body">
      <div class="row">
        <div class="col-md-5">
          <div class="d-flex py-2">
            <div class="align-self-center">
              <i class="fa fa-map-marker" style="font-size: 20px;"></i>
            </div>
            <mat-form-field appearance="outline" class=" ml-2 col-lg-11">
              <mat-label>Select State </mat-label>
              <mat-select [formControl]="stateTypeCtrl" placeholder="Project"
                (selectionChange)="onStateTypeChange($event)">
                <mat-option>
                  <ngx-mat-select-search [formControl]="stateTypeFilterCtrl" placeholderLabel="Select State..."
                    noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="false"
                    toggleAllCheckboxTooltipMessage="Select / Unselect All"
                    [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                    (toggleAll)="toggleStateSelectAll($event)">
                    <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let state of filteredStateTypes | async "
                  style="border-bottom:1px solid #ced4da !important;" [value]="state.stateSno">
                  {{ state.stateName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- <div class="col-md-2">
            <div class="d-flex py-2 justify-content-between">
              <div class="align-self-center">
                <i class="fa fa-ellipsis-v" style="font-size: 20px;"></i>
              </div>
            </div>
          </div> -->

        <div class="col-md-5">
          <div class="d-flex py-2">
            <div class="align-self-center">
              <i class="fa fa-map-marker" style="font-size: 20px;"></i>
            </div>
            <mat-form-field appearance="outline" class=" ml-2 col-lg-11">
              <mat-label>Select District </mat-label>
              <mat-select [formControl]="districtTypeCtrl" placeholder="Project"
                (selectionChange)="onDistTypeChange($event)">
                <mat-option>
                  <ngx-mat-select-search [formControl]="districtTypeFilterCtrl" placeholderLabel="Select District..."
                    noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="false"
                    toggleAllCheckboxTooltipMessage="Select / Unselect All"
                    [toggleAllCheckboxIndeterminate]="isIndeterminate" [toggleAllCheckboxChecked]="isChecked"
                    (toggleAll)="toggleDistrictTypeSelectAll($event)">
                    <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let district of filteredDistrictTypes | async "
                  style="border-bottom:1px solid #ced4da !important;" [value]="district.districtSno">
                  {{ district.districtName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="col-md-2">
          <div class="py-2 mr-2">
            <!-- <button [disabled]="!enableButton" mat-raised-button color="primary" class="mt-3 float-right " (click)="getContactCarrage()">
              Rent Bus
            </button> -->
            <button type="button" class="btn btn-primary mt-3 float-right" (click)="getContactCarrage()"
              [disabled]="!enableButton">Rent Bus</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="col-md-12">
  <!-- <div class="rounded bg-primary p-3 mt-4" *ngIf="isNotTripCalculate">
    <span style="color: rgb(249, 244, 244);">Rent a Bus</span>
  </div>
  <div class="rounded bg-primary p-3 mt-4" *ngIf="!isNotTripCalculate">
    <span style="color: rgb(249, 244, 244);">Rent Details</span>
  </div> -->

  <div class="rounded bg-primary p-3 mt-4" *ngIf="busList.length>0 && show">
    <span *ngIf="busList.length==0 && !show" style="color: rgb(249, 244, 244);">Rent a Bus</span>
    <div class='head-div my-auto' style="color: rgb(249, 244, 244); text-align: center;" *ngIf="isNotTripCalculate">
      {{stateControlName}} - {{districtControlName}}</div>
    <div class='head-div my-auto' style="color: rgb(249, 244, 244);" *ngIf="!isNotTripCalculate">Rent Details</div>
  </div>
  <div *ngFor="let trip of tripDataList; let i = index">
    <div class="card mt-4"
      *ngIf="!isNotTripCalculate && i === 0 && trip.returnTypeCd == 72 || !isNotTripCalculate && i === 1 && trip.returnTypeCd == 73">
      <div class="card-header">
        <div class="mt-2 text-center title" *ngIf="trip.returnTypeCd == 72">One Way Trip</div>
        <div class="mt-2 text-center title" *ngIf="trip.returnTypeCd == 73">Round Trip</div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-2 text-center">
            <div class="align-self-center">
              <i class="fa fa-building-o" style="font-size: 24px;"></i>
            </div>
            <div class="mt-2 direction">{{trip.sourceName}}</div>
            <div class="mt-2 direction" *ngIf="trip.returnTypeCd == 73">{{trip.destinationName}}</div>
            <div class="mt-2" style="font-weight: bolder;">From</div>
          </div>
          <div class="col-1 mt-4 text-center">
            <div class="align-self-center">
              <img *ngIf="trip.returnTypeCd == 72" src="/assets/img/f_arrow.png" style="width: 20px; height: 20px;" />
              <img *ngIf="trip.returnTypeCd == 73" src="/assets/img/double_arrow.png"
                style="width: 30px; height: 30px; margin-top: 20px" />
            </div>
          </div>
          <div class="col-2 text-center">
            <div class="align-self-center">
              <i class="fa fa-building-o" style="font-size: 24px;"></i>
            </div>
            <div class="mt-2 direction">{{trip.destinationName}}</div>
            <div class="mt-2 direction" *ngIf="trip.returnTypeCd == 73">{{trip.sourceName}}</div>
            <div class="mt-2" style="font-weight: bolder;">To</div>
          </div>
          <div class="col-2 text-center">
            <div class="mt-2" style="font-weight: bolder;">Start Date</div>
            <div class="mt-2 date-format">{{trip.tripStartingDate | date:'mediumDate'}}</div>
            <div class="mt-2" style="font-weight: bolder;">End Date</div>
            <div class="mt-2 date-format">{{trip.tripEndDate | date:'mediumDate'}}</div>
          </div>
          <div class="col-2 text-center">
            <div class="mt-2" style="font-weight: bolder;">Total Days</div>
            <div class="mt-2 date-format">{{noOfDaysBooked}}</div>
          </div>
          <div class="col-2 text-center">
            <div class="mt-2" style="font-weight: bolder;">Total Kms</div>
            <div class="mt-2 date-format">{{trip.totalKm | number:'1.2-2'}} km</div>
          </div>
          <div class="col-1 text-center">
            <div class="mt-2">
              <button *ngIf="!showViaRoute" mat-raised-button color="primary" class="mt-5 float-right" (click)="showVia('show')">SHOW VIA</button>
              <button *ngIf="showViaRoute" mat-raised-button color="primary" class="mt-5 float-right" (click)="showVia('hide')">Hide VIA</button>
            </div>
          </div>
        </div>
        <mat-divider class="mt-2" *ngIf="showViaRoute"></mat-divider>

        <div class="row" *ngIf="showViaRoute">
          <div class="col-1 mt-3 text-center">
            <img src="assets/img/route_via.png" />  
          </div>
          <div class="col-11 mt-4" *ngIf="trip.viaNames.length > 0">
            <ng-container *ngFor="let via of trip.viaNames; let last = last">
              <span class="via-name" >{{ via.vaiName }}</span>
              <span class="arrow-via" *ngIf="!last"> -> </span>
            </ng-container>
          </div>
          <div *ngIf="trip.viaNames.length == 0" class="via-name mt-4"> you not select any via route  </div>
        </div>
        
      </div>
      </div>
    </div>
    <div *ngIf="busList.length==0" class="text-center">
      <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive align-self-center" *ngIf="busList.length>0">
        <!-- <div infinite-scroll [infiniteScrollDistance]="modalScrollDistance" [scrollWindow]="isSrollDown"
        [infiniteScrollThrottle]="modalScrollThrottle"> -->
        <div *ngFor="let row of busList; let i = index">
          <mat-card class="rounded-card mt-4">
            <mat-card-content>
              <div class="row">
                <div class="col-2 mt-3">
                  <i class="fa fa-bus pt-2" aria-hidden="true" style="font-size: 50px;"></i>
                </div>
                <div class="col-4">
                  <h1 class="text-primary font-weight-bold">{{row.vehicleName}} </h1>
                  <div class="text-black font-weight-bold">{{row.vehicleRegNumber}} </div>
                </div>
                <div class="col-2 mt-3">
                  <div class="text-secondary">Seats : </div>
                  <div class="text-black font-weight-bold pt-1" *ngIf="row.seatCapacity != null">
                    {{row.seatCapacity}}</div>
                  <div class="text-black font-weight-bold pt-1" *ngIf="row.seatCapacity == null">
                    --- </div>
                </div>
                <div class="col-2 mt-3">
                  <div class="text-secondary">Seat Type : </div>
                  <div class="text-black font-weight-bold pt-1">
                    {{row.seatType}}</div>
                </div>
                <div class="col-2">
                  <button mat-raised-button color="primary" class="mt-5 float-right " data-toggle="modal"
                    data-target=".bd-example-modal-lg" data-dismiss="modal" (click)="getViewBus(i)">View Bus</button>
                </div>

              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- </div> -->
        <div *ngIf="busList.length>0">
          <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="itemPerPage"
            (page)="getMoreCity($event)" aria-label="Select page" [pageIndex]="pageIndex">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->

  <!-------------------------------------------------- Open Model ----------------------------------------------------------->


  <div class="modal fade bd-example-modal-lg" data-backdrop='static' tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header customize">
          <h1 class="modal-title" id="exampleModalLongTitle">
            Bus Deatils
          </h1>
          <!-- <p>Media List{{mediaList | json}}</p> -->
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
       
        <div class="modal-body">
          <div class="card" style="background-color: black;">
            <div style="margin: auto;" *ngIf="mediaList != null">
              <img [src]="currentImage" style="height: 350px; width: 100%;" />
              <i *ngIf="currentIndex < mediaList.length-1" class="fa-sharp fa-solid fa-circle-chevron-right next-button"
                (click)="nextImage()"></i>
              <i *ngIf="currentIndex > 0" class="fa-solid fa-circle-chevron-left prev-button" (click)="preImage()"></i>
            </div>
            <div style="margin: auto;" *ngIf="mediaList == null">
              <img style="height: 350px;" src="assets/img/img-not-found.png" />
            </div>
          </div>
          <div *ngFor="let bus of viewBusList;let l = index">
            <div class="row">
              <div class="col-10">
                <div class="mt-4 text-primary font-weight-bold" style="font-size: x-large;">{{bus.vehicleName}}</div>
                <div><span>
                    <h3>{{bus.seatType}} | {{bus.seatCapacity}} Seats</h3>
                  </span></div>
              </div>
              <div class="col-2" *ngIf="!isNotTripCalculate">
                <div class="mt-4">Approximate</div>
                <div><span>
                    <h2>₹ {{price | number:'1.2-2'}}</h2>
                  </span></div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="card">
                <p>mobile</p>
              </div>
          </div>     -->
            <div class="card rounded-card mt-2 w-50 text-center" *ngIf="show">
              <div class="card-body">
                <div class="row">
                  <div class="col-2 mt-3">
                    <i class="fa fa-mobile" style="font-size: 25px;" aria-hidden="true"></i>
                  </div>
                  <div class="col-2 mt-3">
                    <i class="fa fa-music" style="font-size: 25px;" aria-hidden="true"></i>
                  </div>
                  <div class="col-2 mt-3">
                    <i class="fa fa-briefcase" style="font-size: 25px;" aria-hidden="true"></i>
                  </div>
                  <div class="col-6 mt-3">
                    <button mat-raised-button color="primary" (click)="viewAmenities()">VIEW AMENITIES</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- open_card -->

            <div class="card rounded-card mt-4 w-50 text-center showAmenities" *ngIf="showAmenities">
              <div class="card-header">
                <div class="row">
                  <div class="col-10" style="text-align: start;">Amenities</div>
                  <div class="col-2"><i class="fa fa-times" style="font-size: 25px; float: right;" aria-hidden="true"
                      (click)="closeAmenities()"></i></div>
                </div>
              </div>
              <div class="card-body">
                <div class="text-center">The vehicle may have the following Amenities</div>
                <div class="row mt-4">
                  <div class="col-2">
                    <i class="fa fa-mobile" style="font-size: 25px;" aria-hidden="true"></i>
                  </div>
                  <div class="col-10">
                    <div style="text-align: start;">Charging Point</div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-2">
                    <i class="fa fa-music" style="font-size: 25px;" aria-hidden="true"></i>
                  </div>
                  <div class="col-10">
                    <div style="text-align: start;">Music System</div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-2">
                    <i class="fa fa-briefcase" style="font-size: 25px;" aria-hidden="true"></i>
                  </div>
                  <div class="col-10">
                    <div style="text-align: start;">Overhead Storage</div>
                  </div>
                </div>

                <div class="col-6 mt-3" style="float: right;" (click)="closeAmenities()">
                  <button mat-raised-button color="primary">Close</button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col 6 p-4">
                <div class="mb-2">
                  <div class="text-black">Fuel Type </div>
                  <div class="text-black font-weight-bold pt-1" *ngIf="bus.fuelType != null">
                    {{bus.fuelType}}</div>
                  <div class="text-black font-weight-bold pt-1" *ngIf="bus.fuelType == null">
                    --- </div>
                </div>
                <div class="mb-2">
                  <div class="text-black">Seat Type</div>
                  <div class="text-black font-weight-bold pt-1">
                    {{bus.seatType}}</div>
                </div>

                <div class="mb-2">
                  <div class="text-black">Seat Capacity</div>
                  <div class="text-black font-weight-bold pt-1" *ngIf="bus.seatCapacity != null">
                    {{bus.seatCapacity}}</div>
                  <div class="text-black font-weight-bold pt-1" *ngIf="bus.seatCapacity == null">
                    --- </div>
                </div>
                <div class="mb-2">
                  <div class="text-black">Video Type</div>
                  <div class="text-black font-weight-bold pt-1">
                    {{formattedVideoTypes}}</div>
                </div>
                <div class="mb-2">
                  <div class="text-black">Lighting System</div>
                  <div class="text-black font-weight-bold pt-1">
                    {{formattedLightingSystem}}</div>
                </div>
              </div>

              <div class="col 6 p-4">
                <div class="mb-2">
                  <div class="text-black">Bus Type </div>
                  <div class="text-black font-weight-bold pt-1">
                    {{bus.coolType}}</div>
                </div>
                <div class="mb-2">
                  <div class="text-black">Lucage Allowed</div>
                  <div class="text-black font-weight-bold pt-1">
                    {{bus.luckageCount}}</div>
                </div>

                <div class="mb-2">
                  <div class="text-black">Model</div>
                  <div class="text-black font-weight-bold pt-1" *ngIf="bus.vehicle_model != null">
                    {{bus.vehicle_model}}</div>
                  <div class="text-black font-weight-bold pt-1" *ngIf="bus.vehicle_model == null">
                    --- </div> 
                </div>

                <div class="mb-2">
                  <div class="text-black">Audio Type</div>
                  <div class="text-black font-weight-bold pt-1">
                    {{formattedAudioTypes}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>