export class RouteCategory {
routeSno : number;
operatorSno:number;
sourceCitySno : number;
sourceCityName:string;
destinationCitySno : number;
destinationCityName:string;
activeFlag : boolean;

viaList:any=[];
busList:any=[];
deleteList:any=[];
 }
