<div class="row mt-4 ">
  <!-- <div class="col-md-4 col-sm-12">
    <h3 class="font-weight-bold">Driver Details</h3>
  </div> -->
  <div class="col-md-12  col-sm-12 d-flex ms-auto">
    <div class="input-group w-25">
      <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-search"></i></span></span>
      <input class="form-control" oninput="this.value = this.value.toUpperCase()" (input)="searchDriver.next($event);"
        type="text" placeholder=" Licence / Driver Name ... " />
    </div>
    <button *ngIf="user.roleCd==2 || user.roleCd==1 || isAdmin" data-toggle="modal" data-placement="top" title="View" class="btn bg-primary text-white mx-2 p-2 px-4" 
      data-target="#modalContent" (click)="clearSearch()"><i class="fa fa-plus mr-2"></i>Attach Driver</button>
    <button *ngIf="user.roleCd==2 || user.roleCd==1 || isAdmin" data-toggle="modal" data-placement="top" title="View" class="btn bg-primary text-white mx-2 p-2 px-4"
      (click)="goToAddDriver()"><i class="fa fa-user mr-2"></i>Add New Driver</button>

    <!-- <div class="col-md-3 col-sm-12 my-1">
       
      </div> -->
  </div>
  <!-- <div class="col-8 row text-right">

    <div class="btn-group mb-4 border" role="group" aria-label="Basic example">
     
    </div>
  </div> -->
</div>

<div class="modal" id="modalContent">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-body">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <label class="font-weight-bold pt-2">Licence Number</label>
          <div class="input-group col-lg-12 col-md-12 col-sm-12">
            <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-search"></i></span></span>
            <input class="form-control" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="value"
              (input)="searchDriverLicense.next($event);" type="text" placeholder="Search Licence Number..." />
          </div>
        </div>
        <div *ngIf="driverLicenseList.length >0 ">
          <div>
            <div *ngFor="let driverLicense of driverLicenseList ;let i =index;">
              <div class="card shadow bg-white rounded  mx-auto">
                <div class="card-body">
                  <div class="col">
                    <label class="font-weight-bold">Driver Name :</label>
                    <label class="text-gray">{{driverLicense.driverName}}</label>
                  </div>
                  <div class="col">
                    <label class="font-weight-bold">Driver Number :</label>
                    <label class="text-right">{{driverLicense.driverMobileNumber}}</label>
                  </div>
                  <div class="col">
                    <label class="font-weight-bold">DOB :</label>
                    <label class="text-gray">{{driverLicense.dob | date :'dd/MM/yyyy'}}</label>
                  </div>
                  <div class="col">
                    <label class="font-weight-bold">License Number :</label>
                    <label class="text-gray">{{driverLicense.licenceNumber}}</label>
                  </div>
                  <div class="col">
                    <label class="font-weight-bold">License ExpDate :</label>
                    <label class="text-gray">{{driverLicense.licenceExpiryDate | date :'dd/MM/yyyy'}}</label>
                  </div>
                  <div class="col">
                    <label class="font-weight-bold">Driver License Type :</label>
                    <label class="text-gray">{{driverLicense.drivingLicenceCdVal}}</label>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>
                      <button *ngIf=" driverLicense.acceptStatusCd!=125 && driverLicense.kycStatus!=20" type="button"
                        (click)="save(i, driverLicense.driverName)" class="btn btn-success" data-dismiss="modal">Add</button>
                    </div>
                    <div *ngIf="driverLicense.kycStatus==19" class="text-success px-3 font-weight-bold">
                      <i class="bi bi-check2-circle font-weight-bold"></i> Verified Driver
                    </div>
                  </div>


                  <div *ngIf="driverLicense.kycStatus==20" class="text-danger px-3 font-weight-bold">
                    Driver Not Verified
                  </div>
                  <div *ngIf=" driverLicense.acceptStatusCd==125" class="col-3 float-right">
                    <button type="button" class="btn btn-warning">Requested</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>



<!-- <div class="input-group mb-2">
  <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-search"></i></span></span>
  <input class="form-control" (input)="searchDriver.next($event);" type="text" placeholder="Search Driver Name..." />
</div> -->
<div *ngIf="driverList.length==0" class="text-center mt-5">
  <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
</div>

<div class="table-responsive" *ngIf="driverList.length>0">
  <app-confirm-dialog></app-confirm-dialog>
  <table class="table mt-4 table-striped table-hover">
    <thead>
      <tr class="bg-primary border-0 text-light">
        <th>#</th>
        <th>Driver Name</th>
        <th>Mobile Number</th>
        <th>Licence Number</th>
        <th>(DL) Type</th>
        <th>(DL) ExpDate</th>
        <th>(TL) ExpDate</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let driver of driverList ;let i =index;"
        [ngClass]="driver.kycStatus ==19 ? 'text-dark' : 'text-danger'">
        <td>{{(pageIndex*limit)+i+1}}</td>
        <td>{{driver.driverName}}</td>
        <td>{{driver.driverMobileNumber}}</td>
        <td>{{driver.licenceNumber}}</td>
        <td>{{driver.drivingLicenceCdVal}}</td>
        <td>{{driver.licenceExpiryDate | date :'dd/MM/yyyy'}}</td>
        <td>{{driver.transportlicenceExpiryDate | date :'dd/MM/yyyy'}}</td>
        <td class="align-middle">
          <div class="btn-group" role="group">
            <button data-toggle="tooltip" data-placement="top" title="Edit" class="btn btn-primary btn-sm"
              (click)="gotoViewDriver(i)"><i class="fa fa-eye"></i></button>
            <!-- <button data-toggle="tooltip" data-placement="top" title="Edit" class="btn btn-secondary btn-sm"
              (click)="goToEditDriver(i)"><i class="fa fa-pencil"></i></button> -->
            <button *ngIf="user.roleCd==2 || user.roleCd==1 || isAdmin" data-toggle="tooltip" data-placement="top" title="Delete" class="btn btn-primary btn-sm"
              (click)="deleteDriver(i)"><i class="fa-solid fa-trash-can"></i></button>
          </div>
        </td>

      </tr>
    </tbody>
  </table>
  <div *ngIf="driverList.length>0">
    <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="itemPerPage" (page)="getMoreDriver($event)"
      aria-label="Select page" [pageIndex]="pageIndex">
    </mat-paginator>
  </div>
</div>

<!-- <div class="modal fade pxp-driver-detach-modal" id="pxp-driver-detach-modal" aria-hidden="true" aria-labelledby="driverDetach"
tabindex="-1">
<div class="modal-dialog  modal-dialog-scrollable modal-sm" style="max-width:40% !important;">
    <div class="modal-content" >
        <div class="modal-header">
          <h2 class="modal-title text-danger">You Cannot able to Detach</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center">you cannot detach the driver.the driver is still driving.you can delete only after driver closing the attendance or else you can close the attendance manually</p>
        </div>
    </div>
</div>
</div> -->

<div class="modal fade" id="pxp-driver-detach-modal" tabindex="-1" role="dialog" aria-labelledby="driverDetach"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered .modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title text-danger" id="driverDetach">Unable to Detach</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4 class="text-center">you cannot detach the driver.the driver is still driving.you can delete only after
          driver closing the attendance or else you can close the attendance manually</h4>
      </div>
    </div>
  </div>
</div>

<span #driverDetach href="#pxp-driver-detach-modal" class="d-none" data-toggle="modal">employer-view-job</span>