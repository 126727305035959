<div class="main text-white">
    <br>
    <div class="text-center p-2 mt-2">
        <h1 class="head">Fleet Today</h1>
    </div>
    <hr>

    <div class=" head1 text-center p-2 ">
        User Terms & Condition
    </div>
    <div class=" head2 text-center p-5 ">
        This Terms & Condition describes Our Terms & Condition on the collection, use and disclosure of Your
        agreement
        <!-- <div class=" p-5 ">
            <small>This Privacy Policy describes the Personal Information which we ('Company', 'we', 'us', or 'our') may
                collect, and it provides our approach towards the collection, storage, usage, handling, and sharing of
                or
                dealing with the same in compliance with the applicable laws and regulations</small>
        </div> -->
    </div>
    <hr>
    <!-- <br> -->
</div>

<div class="container">
    <h1>Introduction</h1>
    <div>
        Please read these terms of use “Fleet Today “carefully before using the "www.fleettoday.in", mobile applications
        "Fleet Today" and APIs. This App, Platform or APIs are owned, registered and operated by “Platform Owner” ("we",
        "us", "our"). This Platform or API is a medium through which Services of Platform Owner shall be streamed
        directly to its Platform Users via App or Website. This App, APIs and the Website shall be together referred to
        as the “Platform”. By using this Platform, the Platform Users of the Website or App or any other application
        linked via Platform APIs, hereinafter referred to as "User" or "you" or "your" or "yourself" or “registered
        user” or “guest user” signify in your agreement to be bound by these Terms .
        By visiting our Platform, you engage in our "Service" and agree to the following terms and conditions, including
        those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms
        of Service apply to all users of the Platform, including without limitation users who are browsers, vendors,
        customers, merchants, and/ or contributors of content.
        <br>
        <div>
            <span class="font-weight-bold">Note :</span> PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE ACCESSING
            OR USING THE PLATFORM. BY ACCESSING OR
            USING ANY PART OF THE PLATFORM, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO ALL
            THE TERMS AND CONDITIONS OF THIS AGREEMENT, THEN YOU MAY NOT ACCESS THE PLATFORM OR USE ANY SERVICES. IF
            THESE TERMS OF SERVICE ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO THESE TERMS OF SERVICE.
        </div>
    </div>
    <br>
    <h1>Amendments</h1>
    <div>These Terms of Use are subject to modifications at any time. We reserve the right to modify or change these
        Terms & Conditions and other Platform Owner’s policies at any time by posting changes on the Platform, and you
        are required to update yourself of such changes, if any, by accessing the changes on the Platform on regular
        basis. You shall, at all times, be responsible for regularly reviewing the Terms & Conditions and the other
        Platform Owner’s other associated policies and note the changes made on the Platform. Your continued usage of
        the services after any change is posted establishes your acceptance of the amended Terms & Conditions and other
        policies. As long as you comply with these Terms & Conditions, Platform Owner grants you a personal,
        non-exclusive, non-transferable, limited privilege to access, enter, and use the Platform. By accepting these
        Terms & Conditions, you also accept and agree to be bound by the other terms and conditions and Platform Owner’s
        policies as may be posted on the Platform from time to time.
    </div>
    <br>
    <h1>Disclaimer</h1>
    <div>
        This document is an electronic record which is governed by all laws and regulations of India and their member
        states, which are involved in the Processing of Personal Data under these Terms of Use. By clicking on 'send
        OTP' button, you are consenting that you have read and understood the Terms of Use and agree to be bound by the
        Terms of Use. Your agreement to the Terms of Use shall operate as a binding agreement between you and Platform
        Owner in respect of the usage and services of the Platform.
    </div>
    <br>
    <h1>Eligibility / Authorized Use</h1>
    <div>
        If you prefer to avail Services using the Platform, then you further warrant that you have the requisite legal
        competency and authority to enter into a binding agreement. Persons who are 'incompetent to contract' within the
        meaning of the Indian Contract Act, 1872 including minors, un- discharged insolvents etc. are not eligible to
        use the Platform’s services. If you are registering as a business entity, you represent that you have the
        authority to bind your entity or your organisation to these Terms of Use. You agree to be financially
        responsible for all of your use of the Services (as well as for use of your account by others, including without
        limitation minors living with you). You agree to supervise and be responsible for all usage of minors of the
        Services under your name or account. You also warrant that all information supplied by you or members of your
        household in using the Services, including without limitation your name, email address, street address,
        telephone number, credit card number and that all the information submitted by you is true and accurate. Failure
        to provide accurate information may subject you to civil and criminal penalties.
    </div>
    <br>
    <h1>Services</h1>
    <div>
        The Platform Owner aims to deliver a platform services to run and manage your fleet operations with ease. Our
        end-to-end solutions ensure that we are with you at every step of the entire fleet management life cycle.
        All the services that are provided in the Platform are at the sole discretion of Platform Owner, and you
        acknowledge and agree that Platform Owner can add or provide additional services not listed previously, or can
        remove / suspend / cancel any or all services/products without any notice or liability.
    </div>
    <br>
    <h1>Audit</h1>
    <div>
        Platform Owner at all times reserves its right, but not an obligation, to audit the Content, User Content,
        features available on the Platform or any other material or information posted/uploaded by the Platform User. If
        at any time, this is the sole discretion of Platform Owner that any Content/User Content or any other material
        or Feature of the Platform is in contravention of any law for the time being in force or spirit of the terms and
        conditions of this Agreement or violates the privacy of any person, then Platform Owner may either remove or
        edit or block such material without any notice. Though Platform Owner does not pre-screen the User Content, yet
        that Platform Owner and its designees shall have the right (but not the obligation) in their sole discretion to
        accept, refuse, move, or remove any Contents that are available via Platform.
        Notwithstanding the above-said right, you acknowledge and understand that all User Content, whether publicly
        posted or privately transmitted, are the sole responsibility of the person from which such User Content
        originated. This means that you, and not Platform Owner is entirely responsible for all User Content that you
        have provided to Platform Owner to upload, post, transmit or otherwise make it available via the Platform. You
        further acknowledge and agree that this User Content does not necessarily reflect the views of Platform Owner
        nor we endorse any such User Content.
        Further to this it is informed that we may share the information pertaining to such instance (with or without
        demand) with Government agency for the purpose of verification of identity of User, or for prevention,
        detection, investigation, prosecution and punishment. Therefore, it is advised to you that you should not
        involve in any such activities which violate any applicable law, rules, regulation or breach the terms and
        conditions of this Agreement.
    </div>
    <br>
    <h1>Electronic Communication</h1>
    <div>
        Platform User may take a note of that Platform Owner shall contact you and may send you best offers and/or
        related communications including promotional offers on your given email address and may contact you on your
        other contact numbers. Therefore, by availing our Services you hereby provide your consent and allow us to
        communicate with you on the given contact information and share with you the promotional and other offers.
    </div>
    <br>
    <h1>General </h1>
    <div>
        You agree that no joint venture, partnership, employment, or agency relationship exists between you and Platform
        Owner as a result of these Terms. Our performance of these Terms is subject to existing laws and legal process,
        and nothing contained in these Terms is in derogation of Platform Owner’s right to comply with governmental,
        court and other applicable laws.
        If any part of these Terms is determined to be invalid or un-enforceable pursuant to applicable law (including,
        but not limited to, the warranty disclaimers and liability limitations set forth herein), then such invalid or
        un-enforceable provision shall be superseded by a provision that most closely matches the intent of the original
        provision, and the remainder of these Terms shall continue in effect.
        You may access and use the Platform and the Services either as a registered user or as a guest user. However,
        not all sections of the Platform and Services will be accessible to guest users of Platform User.
    </div>
    <br>
    <div class="h1">
        Your Account and Registration Obligations
    </div>
    <h1>User Account </h1>
    <div>
        “User Account” shall mean the personal account created by the user on the Platform. In order to avail the
        benefits of the Services provided by Platform Owner, the Users must mandatorily register themselves on the
        Platform. Such Users and their details shall be verified by the Platform Owner by a detailed process. Every
        registered user shall allocate themselves a unique identification user name and password ("User ID" and
        "Password").
        If you use the Platform as registered user, you are responsible for maintaining the confidentiality of your User
        ID and Password. You are responsible for all activities that occur under your User ID and Password. You agree,
        inter alia, to provide true, accurate, current and complete information about yourself as prompted by the
        registration form in the Platform. If you provide any information that is untrue, inaccurate, not current or
        incomplete or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or
        incomplete, we reserve the right to indefinitely suspend, terminate or block your access to the Services, the
        Platform and refuse to provide you with access to the Platform.
        The Platform User may access their records relating to the Services at the Platform. You shall ensure that the
        Account Information provided by you in the Platform’s registered account is true, correct, complete, accurate
        and up-to-date. Use of another User's Account Information for availing the Services is expressly prohibited.
        If You provide any information that is not correct, true, inaccurate, not current or incomplete (or becomes not
        true, inaccurate, not current or incomplete), or Platform Owner identifies and/or has reasonable grounds to
        suspect that such information is not true, inaccurate, not current or incomplete, we have the right to suspend
        or terminate Your account and refuse any and all current or future use of the Platform/Services (or any portion
        thereof). In case you prefer to avail and/or are provided an access to specific services and/or offers, then the
        said services and/or offers shall be subject to the respective terms and conditions, notified from time to time
        at the sole discretion of Platform Owner.
    </div>
    <br>
    <div class="h1">
        Links to Third-Party Websites
    </div>
    <div>

        • The Platform may contain links to other websites ("Linked Sites"). Linked Sites are not under the control of
        the Platform Owner. We will not be responsible for the contents of any Linked Site, including without limitation
        any link contained in a Linked Site, or any changes or updates to a Linked Site. Platform Owner is not
        responsible for any form of transmission received from any Linked Site.<br>
        • On accessing the Linked Sites, you shall be governed by the terms of use, privacy policy and such other
        additional policies of the Linked Sites. You further acknowledge and agree that Platform Owner shall not be
        responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
        connection with the use of or reliance on any such content, advertising, products, services or other materials
        available on or through any Linked Sites or for any errors, defamatory content, libel, slander, omissions,
        falsehoods, obscene content, pornographic material, or any profanity contained therein.
    </div><br>
    <div class="h1">
        Information Sharing and Disclosure
    </div>
    <div>
        Platform Owner will not sell or rent your personally identifiable information to anyone. We will send/share
        personally identifiable information about you when: Platform Owner has consent to share the information,
        Platform Owner will need to share your information to provide the Service requested by you, Platform Owner
        responds to subpoenas, court orders or legal process. If we find that your action on the Platform violates our
        terms and conditions or any of your usage guidelines for specific Services or any laws prevailing in India and
        overseas, Platform Owner shall initiate appropriate legal proceedings by taking recourse to all remedies
        available to it in law and as per the Terms and other agreements.
    </div><br>

    <div class="h1">
        Limitation and Disclaimer of Warranty
    </div>
    <div>

        • The Platform, the Services and each portion thereof are provided "AS IS" without warranties of any kind either
        express or implied. To the fullest extent possible pursuant to Applicable Law, we disclaim all warranties,
        express or implied, with respect to the Platform, or the Services and each portion thereof, including, but not
        limited to, no infringement or other violation of intellectual property rights.<br>
        • We shall not be liable for any direct, indirect, incidental or consequential damages, whatsoever incurred by
        the User due to use of the Platform or the Services or due to the non- availability of the Platform or
        Services.<br>
        • We make no representation or warranty that the Platform shall (i) meet your requirements/ be accurate or
        reliable; (ii) be uninterrupted, timely, secure or error-free. Further, access to the Platform or the Services
        shall be contingent to your internet accessibility and we shall not be held liable for any lack/ sporadic breaks
        in your internet accessibility.<br>
        • We shall not be liable in the event any damage or loss occurs to your computer system, or any other electronic
        device, or any data as a result of accessing the Platform or the Services.<br>
        • If you are dissatisfied or harmed by this Platform or anything related with the Platform, your sole remedy
        shall be to terminate these Terms of Use by closing your account.<br>
        • We shall be entitled to disclose to the Authority, as required by Applicable Law or by any directive or
        request from any government body, the particulars of the User engaged with the Platform.<br>
        • We shall be entitled to add, to vary or amend any or all these terms and conditions at any time and the User
        shall be bound by such addition, variation or amendment. Once such addition, variation or amendment is
        incorporated into these terms and conditions, and such amended terms and conditions are on the Platform.<br>
    </div><br>

    <div class="h1">
        Intellectual Property
    </div>
    <div>
        You may not modify, copy, distribute, transmit, display, reproduce, publish, license, create derivative works
        from, transfer, or sell any information, Services obtained from the Platform- directly or indirectly in any
        medium. Neither these materials nor any portion thereof may be stored in a computer except for personal and
        non-commercial use. Platform Owner will not be held liable for any delays, errors or omissions there from, or in
        the transmission or delivery of all or any part thereof, or for any damages arising from any of the foregoing.
        The Platform User acknowledges that Platform Owner is, and shall be the sole owner of all Intellectual Property
        Rights and to any solutions and/or products that have been developed by Platform Owner, so as to enable it to
        render Services to the Platform User. Any rights not expressly granted herein shall be retained by Platform
        Owner only. The Platform, including, but not limited to its graphics, logos, page headers, button icons, scripts
        and service names constitute trade dress of the Platform Owner. The trademarks, domain names and trade dress of
        the Platform Owner shall not be used or reproduced without prior written approval from us, and may not be used
        in connection with any product or service that is not affiliated with us.
    </div><br>

    <div class="h1">
        Copyrights
    </div>
    <div>
        All content included in the Platform, including but not limited to text, graphics, logos, button icons, images,
        audio clips, video clips, digital downloads, data compilations, and software, is/ are the sole property of
        Platform Owner and protected under copyright laws and regulations.
        You may use the Platform content for personal and non-commercial purposes in accordance with the principles
        governing intellectual property laws. Any other use or modification of the content of the Platform without the
        prior written authorization of Platform Owner is expressly prohibited.
    </div><br>
    <div class="h1">
        No Unlawful or Prohibited Use
    </div>
    <div>
        You warrant to Platform Owner that you will not use the Platform for any purpose that is unlawful, illegal or
        prohibited by these Terms. You shall not use the Platform in any manner, which could damage, disable,
        overburden, or impair the Platform or interfere with any other party's use and enjoyment of the Platform. You
        shall not obtain or attempt to obtain any materials through any means not intentionally made available or
        provided for through the Platform.
    </div><br>

    <div class="h1">
        Indemnification and Limitation of Liability
    </div>
    <div>
        <span>Indemnity : </span> You agree to indemnify, defend and hold harmless the Platform Owner and its
        subsidiaries, affiliates,
        vendors, agents and their respective directors, officers, employees, contractors and agents (herein after
        individually and collectively referred to as "Indemnified Parties") from and against any and all losses,
        liabilities, claims, suits, proceedings, penalties, interests, damages, demands, costs and expenses (including
        legal and other statutory fees and disbursements in connection there with and interest chargeable thereon)
        asserted against or incurred by the Indemnified Parties that arise out of, result from, or in connection with:-
        • Your breach of the Agreement(s).<br>
        • Any claims made by any third party due to, or arising out of, or in connection with, your use of the
        Platform;<br>
        • Any claim that any Content / User Content / Third Party Content, information or materials provided by you
        caused damage to a third party;<br>
        • Your violation of any rights of another, including any intellectual property rights.
        Limitation of Liability: Platform Owner assumes no liability whatsoever for any monetary or other damage
        suffered by you on account of:<br>
        • The delay, failure, interruption, or corruption of any data or other information transmitted in connection
        with use of the Platform;<br>
        • Any interruption or errors in the operation of the Platform.
        Notwithstanding anything to contrary in the Agreement(s), in no event shall Platform Owner, its subsidiaries or
        affiliates and their respective officers, directors, employees, partners or suppliers be liable to you for any
        special, incidental, indirect, consequential, exemplary or punitive damages whatsoever, including those
        resulting from loss of use, data or profits, whether or not foreseeable or whether or not Platform Owner has
        been advised of the possibility of such damages, or based on any theory of liability, including breach of
        contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection
        with Your use of or access to the Platform, Services, Content, User Content or Third Party Content.
        The entire and aggregate liability of Platform Owner towards You under and in relation to these Terms of Use or
        otherwise shall not exceed the greater of Indian Rupees One Hundred Only (INR 100/-) or the amount of fees, if
        any, paid by you to Platform Owner under the relevant order to which the cause of action for the liability
        relates.
    </div><br>

    <div class="h1">
        User Comments
    </div>
    <div>
        The Platform Owner does not assume any obligation to monitor the information that Users post / may post on the
        Platform. You warrant that any and all information, projects, files or other attachments sent to us ('User
        Material') or comments other than personal data, which you may transmit to the Platform Owner through the
        Platform and does not infringe intellectual property rights or any other applicable laws. Such information,
        material or comments, will be treated as non-confidential and non-proprietary. By submitting any information or
        material, you give us an unlimited and irrevocable license to use, execute, show, modify and transmit such
        information, material or comments, including any underlying idea, concept or know-how. We reserve the right to
        use such information, in any way in its sole discretion.
    </div><br>

    <div class="h1">
        Language and Display
    </div>
    <div>
        Platform Owner do not intend to or have displayed and/ or used any language, which hurt the sentiments of any
        person nor intend to or have displayed anything, which would have or deemed to be defamatory in nature. If you
        feel any language or display as derogatory then You may contact us and write to our Grievance Officer.
        Nevertheless, Platform Owner do not own any kind of claim of such nature in case any such query is raised
        against the Platform and we reserve all rights to defend itself against any such claims.
    </div><br>

    <div class="h1">
        Dispute Resolution & Governing Laws
    </div>
    <div>
        You may write to Platform Owner for any issues arising out of the usage of the Platform to Grievance Officer and
        Platform Owner shall make all efforts to support you to resolve the same. However, Platform Owner do not own any
        responsibility for any such issues and in case of any dispute arising out of the same by you against us then the
        same shall be referred to an Arbitrational Tribunal consisting of a Sole Arbitrator to be appointed by Platform
        Owner and whose decision shall be final and binding. The venue of arbitration shall be Chennai and language
        shall be English. By using the Platform, you provide consent for this clause as well.
        In case of any dispute, this Agreement shall be governed by the laws of India. By using the Platform, you hereby
        consent to the exclusive jurisdiction and venue of courts in Chennai, India, irrespective of your point of
        access to the Platform. Use of Platform is unauthorized in any jurisdiction that does not give effect to all
        provisions of these Terms, including without limitation to this paragraph.
    </div><br>
</div>


<!-- <footer>
    <div class="text-center p-4">
        In the event you have any complaints or concerns with respect to the Platform or our services or if you have any
        questions about this Privacy Policy, please write us at <span
            class="text-primary">radiantfleetstack@gmail.com</span>
    </div>
</footer> -->