<div class="main text-white">
    <br>
    <div class="text-center p-2 mt-2">
        <h1 class="head">Fleet Today</h1>
    </div>
    <hr>

    <div class=" head1 text-center p-2 ">
        User Privacy Policy
    </div>
    <div class=" head2 text-center p-5 ">
        This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
        information
        <!-- <div class=" p-5 ">
            <small>This Privacy Policy describes the Personal Information which we ('Company', 'we', 'us', or 'our') may
                collect, and it provides our approach towards the collection, storage, usage, handling, and sharing of
                or
                dealing with the same in compliance with the applicable laws and regulations</small>
        </div> -->
    </div>
    <hr>
    <!-- <br> -->
</div>

<div class="container">
    <h1>Introduction</h1>
    <div>
        This Privacy Policy describes the Personal Information which we ('Company', 'we', 'us', or 'our') may collect,
        and it provides our approach towards the collection, storage, usage, handling, and sharing of or dealing with
        the same in compliance with the applicable laws and regulations.
        We request you to please read the terms of this Privacy Policy carefully. By using and continuing to use our
        Applications and Services, you are deemed to have accepted and consented to the terms of this Privacy Policy.
        Subject to applicable laws, please note that acceptance of this Privacy Policy in its entirety is imperative for
        us to provide you access to our Application and Services. Hence if you do not accept or agree to any part of
        them, then we request you to please stop the usage of our Applications and Services.
    </div>
    <br>
    <h1>Collection of Personal Information</h1>
    <div>
        We have implemented security standards and processes which are in line with industry standards and the
        applicable law in order to ensure the protection of your information at every stage which includes collection,
        storage, usage, handling and sharing. .
        At the time you (i) register or use any of the Company’s applications, websites "Fleet Today" for using the
        services, (ii) share any information through the Application or otherwise (iii) contact us through our help and
        support channels or, (iv) otherwise interact with us during the course of our relationship, we may ask you for
        your personal information (such as your name, telephone number, email address, geo-location, address, payment
        information, certain unique identifiers, and any other information or data that can be used by itself to
        uniquely identify, contact or locate a person, or can be used with information available from other sources to
        uniquely identify an individual ) ("Personal Information").
        As a part of using our Application(s) and Service, we may collect certain information which includes geo
        location, internet protocol (IP) address, device usage and information, advertising identifiers, browser type,
        operating system, internet service provider, the web page that directed you to our services, the date and time
        of your visit, information about how you engage with us, information about how and when you use our services and
        other standard server log information.
        Information is also collected through cookies and similar technologies. You can find out more about this in our
        Cookie Policy which is deemed to be a part of this Privacy Policy. You can opt-out of allowing us to collect
        this information either by refusing access to the information. However, if you choose to opt-out, you may not be
        able to use certain aspects of the Services or Application. The Personal Information may be collected by the
        Company through itself or its authorized third parties.
    </div>
    <br>
    <h1>Use of Personal Information</h1>
    <div>
        We and our group companies may use, collect, store, process and transfer your Personal Information in accordance
        with the applicable laws and regulations, for a variety of purposes. These may include but are not limited to
        <br>
        • to identify you from time to time, as required, and to validate your use of the Application and or
        Services;<br>
        • to complete transactions effectively and bill for Services;<br>
        • to communicate and for resolving customer service issues;<br>
        • to respond to your queries and fulfil your requests for information about the various Services;<br>
        • to send you surveys, marketing communications, important information regarding the Application, changes in
        terms and conditions, user agreements, and policies and/or other administrative information;<br>
        • to ensure the smooth functioning, maintenance and improvement of our Applications and Services;<br>
        • to protect the IP and integrity of the Application.<br>
        • to enforce our terms of service;<br>
        • to comply with laws, requests from government bodies or courts, prevent and detect frauds and crimes or to
        respond to litigation;<br>
        • to implement information security practices, determine any security breaches, computer contaminants or
        computer viruses, investigate, prevent, or take action regarding illegal activities and suspected fraud and
        undertake forensics of the concerned computer resource as a part of an investigation or internal audit.<br>
        • to conduct internal assessments, auditing, data analysis, and research to improve our products and
        services;<br>
        • to promote and market products and services that we consider may be of interest to you and/or may benefit
        you;<br>
        • undertaking mergers, acquisitions, reorganizations, subject to applicable laws;<br>
        • aggregate your data with other users' data to create statistics about the general use of the Application which
        helps us to develop new products and services<br>
        • to share your Personal Information with affiliates and group companies for any of the foregoing purposes,
        which affiliates may also combine your Personal Information with other information in this regard.<br>
    </div>
    <br>
    <h1>Disclosure to Third Parties</h1>
    <div>
        Subject to applicable law, Company shall be at liberty to disclose your information or part thereof to
        third-party service providers who may facilitate the providing of Services on our behalf. These service
        providers have access to your data only to perform the tasks as stipulated by us and are obligated not to
        disclose or use it for any other purpose. We may also share personal information with third-party vendors,
        consultants, and other service providers who work for us, who are bound by contractual obligations to keep
        personal information confidential. Subject to confidentiality terms, your information may be shared or
        transferred in connection with any merger, sale of company assets, financing or acquisition. We may share your
        information with our affiliates or group companies, in which case we will require those affiliates to comply
        with this Privacy Policy.
        In case of transfer of Personal Information from any country, we shall comply with any measures mandated by the
        applicable law of the said country. The rights and protection granted to you under the applicable law and in
        view of nature of transactions, shall at all times be complied with by using contractual measures and/or other
        measures designed to ensure third-party service providers who may facilitate the providing of Services on our
        behalf protect the Personal Information. For eg, in cases we transfer personal data from the European Economic
        Area (EEA) we will ensure such transfers are in compliance with relevant data protection laws, including usage
        of EU Standard Contractual Clauses or compliance with requirements under GDPR, as the case may be.
        As a part of your use of our Application and Services and subject to applicable laws, the information and
        personal data you provide to us may be transferred to countries other than the country from where it is
        collected. By submitting your information and personal data to us, you agree to the transfer, storage, and/or
        processing of such information and personal data in the manner described above. In such cases we will take all
        necessary measures to protect your personal information in accordance with this Privacy policy and applicable
        laws.
        <br>
        In case there is any third-party plug-ins or services provided by the Company and the same results in the use of
        your Personal Information, such usage will be governed by their privacy policy (which should be accessible from
        their website or application). If you do not want third parties to be able to access or use your Personal
        Information, you should not register for, click on or use the applicable third-party application or product or
        services. We do not have any control over and do not take any responsibility of the privacy and security
        practices and policies of such third parties, including other websites, services, or applications that may be
        linked to or from the Services. We do not make any guarantee regarding any such third parties, and we will not
        be liable for any loss or damage caused by the use of such third-party websites, services, or applications. You
        should review the policies of such third parties and contact them directly to respond to your questions.

    </div>
    <br>
    <h1>Legal basis for processing of Personal Information</h1>
    <div>
        We only process your Personal Information when we believe it is necessary and we have a legal basis to do so
        under the applicable laws. The legal basis may vary based on the law applicable and includes but is not limited
        to your consent and/or to comply with laws and/or to provide you with services to enter into or fulfil our
        contractual obligations and/or to protect your rights and/ or to fulfil our legitimate business interests.
    </div>
    <br>
    <h1>Security Procedures</h1>
    <div>
        We have implemented appropriate and reasonable technical and organizational security measures and applicable
        laws, thereby designed to protect the security of any Personal Information we process.
        While we observe reasonable security measures, security risks may still arise for reasons outside of our control
        such as hacking, cyber-attacks, force majeure events, etc. Hence, the technical and organizational security
        measures do not guarantee absolute protection of Personal Information. Please note that it is the User's
        responsibility to protect the security of their login information.
    </div>
    <br>
    <h1>Data Access and Rights</h1>
    <div>
        Subject to the nature of the transaction between us and the user and the rights granted under the applicable
        laws, we shall provide certain rights like right to request access and obtain a copy of your personal
        information, the right to request rectification or erasure, right to restrict the processing of your personal
        information, right to restrict or object processing, right to data portability, rights relating to automated
        decision-making and profiling etc. You can make such a request by contacting us by using the contact details
        provided in this Privacy Policy. Please note that we will consider and act upon any request in accordance with
        the applicable data protection laws of the said country and the nature of the transaction between the user and
        the Company.
    </div>
    <br>
    <h1>Amendment</h1>
    <div>
        The company reserves the right to add, revise, amend, modify or delete any part of this Privacy Policy (in part
        or in full) at its discretion. The updated version of this Privacy Policy in force will be posted on the
        Platform from time to time. In the event of any change in the applicable laws, this Privacy Policy shall be
        deemed to be amended or modified to the extent necessary to comply with any requirement under the applicable
        laws. Your continued use of the Application and services after the effective date of the amended or modified
        Privacy Policy will be deemed to be your agreement to the modified Privacy Policy.
    </div>
    <br>
</div>

<footer>
    <div class="text-center p-4">
        In the event you have any complaints or concerns with respect to the Platform or our services or if you have any
        questions about this Privacy Policy, please write us at <span class="text-primary">radiantfleetstack@gmail.com</span>
    </div>
</footer>