<div class="container">
    <div class="row justify-content-md-end">
      <div class="col-auto my-4">
        <div class="input-group">
          <div class="input-group-prepend mx-auto" style="width:50px">
            <span class="input-group-text  text-center" style="width:50px"><i class="fa fa-search mx-auto"></i></span>
          </div>
          <input (input)="searchdrivingvehicle.next($event);" [(ngModel)]="searchKey"  type="text" style="height:42px"
            class="w-25" placeholder="Search Vehicle Number..." class="form-control border-1 ">
        </div>
      </div>
      <!-- <div class="col-auto">
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>Filter By Vehicle Type</mat-label>
          <mat-select [formControl]="vehicleTypeCtrl" (ngModelChange)="getOperaterVehicle()" placeholder="Project"
            [multiple]="true">
            <mat-option>
              <ngx-mat-select-search [formControl]="vehicleTypeFilterCtrl" placeholderLabel="Find vehicle type..."
                noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleVehicleTypeSelectAll($event)">
                <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let vehicleType of filteredVehicleTypes | async "
              style="border-bottom:1px solid #ced4da !important;" [value]="vehicleType.codesDtlSno">
              {{ vehicleType.cdValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <!-- <div class="col-auto">
        <mat-form-field appearance="outline" class="ml-2">
          <mat-label>Order by Expiry Type</mat-label>
          <mat-select [formControl]="vehicleExpiryCtrl" (ngModelChange)="getOperaterVehicle()" placeholder="Project"
            [multiple]="false">
            <mat-option>
              <ngx-mat-select-search [formControl]="vehicleExpiryFilterCtrl" placeholderLabel="Find expiry type..."
                noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleVehicleExpirySelectAll($event)">
                <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let expiry of filteredExpiryTypes | async "
              style="border-bottom:1px solid #ced4da !important;" [value]="expiry.name">
              {{ expiry.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
      <!-- <div class="col-auto" *ngIf="appUser.roleCd==2 || appUser.roleCd==1 || isAdmin">
        <button mat-raised-button color="primary" class="mt-3" (click)="goToAddNewVehicle()">
          <i class="fa fa-plus mr-2" aria-hidden="true"></i>
          &nbsp;Add Vehicle
        </button>
      </div> -->
    </div>
  </div>
  
  
  <div *ngIf="vehicleDriveList.length==0" class="text-center">
    <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
  </div>

  <div *ngFor="let drive of vehicleDriveList ;let i =index;">
    <div class="table-responsive" *ngIf="vehicleDriveList.length>0">
      <!-- <app-confirm-dialog></app-confirm-dialog> -->
      <table class="table  table-hover table-striped">
        <thead>
          <tr class="bg-primary border-0 text-light">
            <th>#</th>
            <!-- <th>Vehicle Name</th> -->
            <th>Registration Number</th>
            <th>Driver Name</th>
            <th>Mobile Number</th>
            <th>Drive Start Value</th>
            <th>Drive Start Date</th>
            <th>No. Of Days</th>
            <th>Status</th>
            <th>Actions</th>
            <!-- <th *ngIf="vehicleExpiryCtrl.value != 'All'">{{vehicleExpiryCtrl.value}}</th> -->
            
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{(pageIndex*limit)+i+1}}</td>
            <!-- <td>{{drive.VehicleName}}</td> -->
            <td>{{drive.vehicleRegNumber}}</td>
            <td>{{drive.driverName}}</td>
            <td>{{drive.mobileNumber}}</td>
            <td>{{drive.startValue}}</td>
            <td>{{drive.startTime | date :'dd/MM/yyyy'}}</td>
            <!-- <td class="align-middle text-truncate text-light">
              <span *ngIf="row.kycStatus =='KYC Not Verified' && !row.rejectReason "
                class="badge rounded-pill bg-danger p-2  px-3 text-light">Not
                Verified</span>
              <span *ngIf="row.kycStatus =='KYC Verified'" class="badge p-2  px-3 rounded-pill bg-success text-light">Verified</span>
              <span *ngIf="row.kycStatus =='KYC Not Verified'&& row.rejectReason " class="badge p-2  px-3 rounded-pill bg-danger text-light">
                Rejected</span>
            </td> -->
            <!-- <td class="align-middle text-truncate text-light">
              <span *ngIf="row.kycStatus =='KYC Not Verified'"
                class="badge rounded-pill bg-secondary p-2  px-3 text-light">Not
                Verified</span>
              <span *ngIf="row.kycStatus =='KYC Verified'" class="badge p-2  px-3 rounded-pill bg-success text-light">Verified</span>
              <span *ngIf="row.kycStatus !='KYC Not Verified' && row.rejectReason " class="badge p-2  px-3 rounded-pill bg-danger text-light">
                Rejected</span>
            </td> -->  
            <td>{{drive.startTime | dateAgo}}</td>
            <td class="text-success">Running</td>
            <td class="align-middle">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#driveModal">
                  End Drive
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="count">
        <mat-paginator [length]="count" [pageSize]="pageSize" [pageSizeOptions]="itemPerPage"
          (page)="getMoreVehicleDriver($event)" aria-label="Select page" [pageIndex]="pageIndex">
        </mat-paginator>
      </div>
    </div>

    <div class="modal fade" id="driveModal" tabindex="-1"  data-backdrop='static' role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">End Driving</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          
          <div class="modal-body">
            <form class="p-2"  [formGroup]="endDriverAttendance">
                <div class="row">
                  <div class="form-group col-md-6 col-sm-12">
                      <label class="text-gray">Driving End Date <span class="text-danger">*</span></label>
                      <input type="datetime-local"  [min]="dueDate" (change)="dateChange(i)" class="form-control" id="input-11" formControlName="endTime">
                      <!-- <small class="text-danger"
                          *ngIf="driverAttendance.controls.endTime?.touched && driverAttendance.controls.endTime?.errors?.required">
                          Driving End Date is required
                      </small> -->
                  </div>
      
      
                  <div class="form-group col-md-6 col-sm-12">
                      <label class="text-gray">Starting Odometer Reading <span
                              class="text-danger">*</span></label>
                      <input type="number" class="form-control" id="input-11" (change)="onChangeValue(i)" formControlName="endValue">
                      <small class="text-danger" *ngIf="maxValue">
                        End Odometer Reading is must be greater than Start Odometer Reading
                    </small>
                  </div>
                </div>
            </form>
          </div>
          
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" #myModalClose>Close</button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            <button type="button" class="btn btn-primary  mb-1 mr-3" (click)="save(i)"
            [disabled]="!endDriverAttendance.valid || maxValue || isLoad">
            <!-- <span class="spinner-border spinner-border-sm" *ngIf="isLoad"></span>&nbsp;&nbsp; -->
            Save
        </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- <ng-template #modalContent>
    <div class="modal-body bg-gray">
      <h6 class="bg-gray text-center" style="padding-bottom: 3px; color:white;    font-size: 14px;
          font-weight: bold;">{{action}}</h6>
      <div class="row">
        <div class="col-12 vertical-tabs">
  
          <div class="col-12 vertical-tabs bg-white">
            <table class="table table-hover">
              <tr>
                <td width="50%" class="text-right">
                  <label class="text-secondary">Vehicle Name</label>
                </td>
                <td width="50%" class="text-left color-blue"><label> {{selectedVehicle?.vehicleName}}</label>
                </td>
              </tr>
  
              <tr>
                <td width="50%" class="text-right">
                  <label class="text-secondary">Vehicle RegNumber</label>
                </td>
                <td width="50%" class="text-left color-blue"><label> {{selectedVehicle?.vehicleRegNumber}}</label>
                </td>
              </tr>
  
              <tr>
                <td width="50%" class="text-right">
                  <label class="text-secondary">Vehicle TypeName</label>
                </td>
                <td width="50%" class="text-left color-blue"><label> {{selectedVehicle?.vehicleTypeName}}</label>
                </td>
              </tr>
  
              <tr>
                <td width="50%" class="text-right">
                  <label class="text-secondary">Kyc Status</label>
                </td>
                <td width="50%" class="text-left color-blue"><label> {{selectedVehicle?.kycStatus}}</label>
                </td>
              </tr>
            </table>
            <div class="col-12 bg-white text-center py-1">
              <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </ng-template> -->