<!-- <div class="bg-primary p-2 rounded text-start ms-auto">
  <p class=" fs-5 text-light pt-2">Bus Booking Details</p>
</div>
<div widget class="card">
  <div class="card-header transparent border-0 text-muted">
    <div class="widget-controls">
      <a data-widgster="fullscreen" href="#" class="transition"><i class="fa fa-expand"></i></a>
      <a data-widgster="restore" href="#" class="transition"><i class="fa fa-compress"></i></a>
    </div>
  </div>
  <div class="card-body pt-0 widget-body">
    <ng-template #modalContent let-close="close">
      <div class="modal-header">
        <h1 class="modal-title">Trip Details</h1>
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"> -->
<!-- <div>
          Action:
          <pre>{{ modalData?.action }}</pre>
        </div> -->
<!-- <div>
          <h2>Customer details:</h2>

          <h3>Name: <span><pre>{{ modalData?.event?.title}}</pre></span></h3>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">OK</button>
      </div>
    </ng-template>

    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-gray" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
            Previous
          </div>
          <div class="btn btn-secondary" mwlCalendarToday [(viewDate)]="viewDate">
            Today
          </div>
          <div class="btn btn-gray" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
            Next
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-gray" (click)="view = 'month'" [class.active]="view === 'month'">
            Month
          </div> -->
<!-- <div class="btn btn-gray" (click)="view = 'week'" [class.active]="view === 'week'">
            Week
          </div>
          <div class="btn btn-gray" (click)="view = 'day'" [class.active]="view === 'day'">
            Day
          </div> -->
<!-- </div>
      </div>
    </div>
    <br>
    <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)">
      </mwl-calendar-month-view> -->
<!-- <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-month-view> -->
<!-- <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-day-view> -->
<!-- </div>
  </div>
</div> -->


<!-- <div class="bg-primary p-2 rounded text-start ms-auto">
  <p class=" fs-5 text-light pt-2">Bus Booking Details</p>
</div>
<div widget class="card">
  <div class="card-header transparent border-0 text-muted">
    <div class="widget-controls">
      <a data-widgster="fullscreen" href="#" class="transition"><i class="fa fa-expand"></i></a>
      <a data-widgster="restore" href="#" class="transition"><i class="fa fa-compress"></i></a>
    </div>
  </div>
  <div class="card-body pt-0 widget-body">
    <ng-template #modalContent let-close="close">
      <div class="modal-header">
        <h1 class="modal-title">Trip Details</h1>
        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <h2>Customer details:</h2>

          <h3>Name: <span><pre>{{ modalData?.event?.title}}</pre></span></h3>

        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="close()">OK</button>
      </div>
    </ng-template>

    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-gray" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate">
            Previous
          </div>
          <div class="btn btn-secondary" mwlCalendarToday [(viewDate)]="viewDate">
            Today
          </div>
          <div class="btn btn-gray" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate">
            Next
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-gray" (click)="view = 'month'" [class.active]="view === 'month'">
            Month
          </div>
        </div>
      </div>
    </div>
    <br>
    <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="'month'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)">
      </mwl-calendar-month-view>
    </div>
  </div>
</div> -->

<div>
  <ejs-schedule [currentView]="setView" [eventSettings]="{ dataSource: events }" (popupOpen)="onPopupOpen($event)"
    height="700px" width="100%"></ejs-schedule>
  <!-- <ejs-schedule [?currentView]="setView" [eventSettings]="{ dataSource: events }"></ejs-schedule> -->
</div>