<div class="row mt-4 ">
    <div class="container-fluid">
      <div class="row justify-content-md-end">
        <div class="col-auto" style="margin-right: auto;">
            <h3 class="font-weight-bold">Driver Details</h3>
        </div>
        <div class="col-auto my-2">
          <div class="input-group mb-2 ">
            <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-search"></i></span></span>
            <input (input)="search.next($event);" [(ngModel)]="searchKey" type="text" style="height:42px"
                        class="w-25" placeholder="License / Driver Name..." class="form-control border-1 ">
          </div>
        </div>
        <div class="col-auto">
              <mat-form-field appearance="outline" class="ml-2">
                <mat-label>Filter By District</mat-label>
                <mat-select [formControl]="driverDistrictCtrl" (ngModelChange)="getDriverDetail()" placeholder="Project"
                  [multiple]="true">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="driverDistrictFilterCtrl" placeholderLabel="Find District..."
                      noEntriesFoundLabel="'no matching found'" [showToggleAllCheckbox]="true"
                      toggleAllCheckboxTooltipMessage="Select / Unselect All" [toggleAllCheckboxIndeterminate]="isIndeterminate"
                      [toggleAllCheckboxChecked]="isChecked" (toggleAll)="toggleDistrictSelectAll($event)">
                      <mat-icon ngxMatSelectSearchClear style="font-size: 14px;">close</mat-icon>
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let district of filtereddriverDistrict | async "
                    style="border-bottom:1px solid #ced4da !important;" [value]="district.districtSno">
                    {{ district.districtName }}
                  </mat-option>
                  <!-- <mat-option *ngFor="let district of filtereddriverDistrict | async "
                    style="border-bottom:1px solid #ced4da !important;" [value]="district">
                    {{district}}
                  </mat-option> -->

                </mat-select>
              </mat-form-field>
        </div>
      </div>
    </div>
  </div>

<span>
    <div *ngIf="driverList.length==0" class="text-center">
        <img class="mt-2 mb-2 w-25" src="assets/img/norecord.png" />
    </div>

    <span *ngIf="driverList.length>0">
        <div class="row">
            <div class="col-12">
                <div class="table table-hover table-responsive  table-striped">
                    <table class="table table-hover">
                        <thead class="bg-primary text-light">
                            <tr>
                                <th>#</th>
                                <th>Licence Number</th>
                                <th>Driver Name</th>
                                <th>Licence Type</th>
                                <th>Licence Expiry </th>
                                <th>Transport Licence Expiry</th>
                                <th>KYC Status</th>
                                <!-- <th>Status</th> -->
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of driverList ;let i =index;">
                                <td class="align-middle">{{(pageIndex*limit)+i+1}}</td>
                                <td class="align-middle">{{row.licenceNumber}}</td>
                                <td class="align-middle">{{row.driverName}}</td>
                                <td class="align-middle">{{row.drivingLicenceCdVal}}</td>
                                <td class="align-middle">{{row.licenceExpiryDate | date: 'dd/MM/yyyy'}}</td>
                                <td class="align-middle">{{row.transportlicenceExpiryDate | date: 'dd/MM/yyyy' }}</td>
                                <td class="align-middle text-truncate">
                                    <span *ngIf="row.kycStatus == 20 "
                                        class="badge rounded-pill bg-secondary text-light">Not
                                        Verified</span>
                                    <span *ngIf="row.kycStatus == 19 "
                                        class="badge rounded-pill bg-success text-light">Verified</span>
                                    <span *ngIf="row.kycStatus != 20 && row.rejectReason "
                                        class="badge rounded-pill bg-danger text-light">
                                        Rejected</span>

                                </td>
                                <!-- <td class="align-middle">
                                    <span class="text-success h6"
                                        *ngIf="row?.kycStatus != 20 && row?.kycStatus != 58">Running</span>
                                    <span class="text-danger h6"
                                        *ngIf="row?.kycStatus != 20 && row?.kycStatus != 19">Out of service</span>
                                    <span class="text-secondary h6" *ngIf="row?.kycStatus == 20">Not Verified</span>
                                </td> -->
                                <td class="align-middle">
                                    <div class="btn-group" role="group">
                                        <!-- <button *ngIf="row.kycStatus != 19" title="Approve" data-target="#approve-modal"
                                            data-toggle="modal" class="btn btn-primary btn-sm" (click)="openModal(i)"><i
                                                class="fa fa-check"></i>
                                        </button> -->
                                        <button title="View row" data-target="#viewModel" data-toggle="modal"
                                            (click)="gotoViewDriver(i)" class="btn btn-primary btn-sm">
                                            <i class="fa fa-eye"></i>
                                        </button>

                                        <!-- <button title="update" data-toggle="tooltip" class="btn btn-primary btn-sm"
                                            (click)="goToEditProject(i,'add')">
                                            <i class="fa fa-pencil"></i>
                                        </button> -->

                                        <!-- <button title="Reject" *ngIf="row.kycStatus !='KYC Verified'"
                                            data-target="#reject-modal" data-toggle="modal" (click)="openModal(i)"
                                            class="btn btn-primary btn-sm">
                                            <i class="fa fa-ban"></i>
                                        </button> -->
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </span>
    <div >
        <mat-paginator [length]="count" [pageSize]="pageSize" *ngIf="driverList?.length"
            [pageSizeOptions]="itemPerPage" (page)="getMoreDriver($event)"
            aria-label="Select page" [pageIndex]="pageIndex">
        </mat-paginator>
    </div>
</span>




<div class="modal fade" id="reject-modal" role="dialog" tabindex="-1" aria-labelledby="modal-error-label"
    aria-hidden="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-danger">
                <h4 class="modal-title" id="modal-error-label"><i class="fa fa-warning"></i>Reject Reason Operator KYC
                </h4>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <textarea class="form-control" style="height: 100px;" id="pxp-candidate-about"
                    placeholder="Type reason here..." [(ngModel)]="reason"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" [disabled]="!reason" data-dismiss="modal"
                    (click)="changeStatus('Reject')">Yes</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>



<div class="modal fade" id="approve-modal" role="dialog" tabindex="-1" aria-labelledby="modal-approve-label"
    aria-hidden="false">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-success">
                <h4 class="modal-title" id="modal-approve-label"><i class="fa fa-warning"></i>Approve Driver KYC</h4>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                Are you sure to approve this Driver Kyc?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal"
                    (click)="changeStatus('Approve')">Yes</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="viewModel" tabindex="-1" role="dialog" aria-labelledby="viewModelLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content col-12 col-md-12 col-sm-12">
            <div class="modal-header">
                <h4 class="modal-title font-weight-bold" id="viewModelLabel">Vehicle Detail</h4>
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body col-12 col-md-12 col-sm-12">
                <!-- <div class="row mb-1 m-0">
                    <div class="col d-flex">
                        <div class="pxp-jobs-tab-pane-category-icon"><span class="fa fa-bus"></span></div>&nbsp;
                        <span>{{selectedDriver?.vehicleRegNumber}}</span>
                    </div>
                </div> -->

                <div class="container mt-3">
                    <div class="row mb-4 text-center">
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="font-weight-bold mb-2">Vehicle Number</div>
                            <p class="text-capitalize">{{selectedDriver?.vehicleRegNumber}}</p>
                        </div>
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="font-weight-bold mb-2">Vehicle Name</div>
                            <p>{{selectedDriver?.vehicleName}}</p>
                        </div>
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="font-weight-bold mb-2">Vehicle Type</div>
                            <div>
                                <span *ngIf="selectedDriver?.vehicleTypeName">{{selectedDriver?.vehicleTypeName}}</span>
                                <span *ngIf="!selectedDriver?.vehicleTypeName">-</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-4" *ngIf="selectedDriver?.ownerList?.ownerName">
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="mb-2 font-weight-bold">
                                <span class="fa fa-phone" aria-hidden="true"></span> Contact
                            </div>
                            <div *ngFor="let contact of selectedDriver?.ownerList">
                                <div>Owner Name:&nbsp;<span>{{contact.ownerName}} {{selectedDriver?.ownerList.length}}
                                    </span>
                                </div>
                                <div>Mobile:&nbsp;<span>{{contact.ownerNumber}}</span></div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-4 text-center">
                        <div class="col-12 col-md-12 col-sm-12">
                            <div class="mb-2 font-weight-bold">
                                KYC Status
                            </div>
                            <div>
                                {{selectedDriver?.kycStatus}}
                            </div>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>