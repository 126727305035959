<!-- <p>change-password works!</p> -->

<!-- <div class="d-flex justify-content-center align-items-center w-100 h-100 login-container">

    <div class="col-xl-1 col-md-1 col-1">

    </div> -->

    <div class="col-xl-3 col-md-3 col-sm-12 login-container ">
        <div class="card border box-shadow rounded-0" style="opacity: 0.9;background-color: #524141;">
            <div class="card-header d-flex h-50 justify-content-center align-items-center border-0 box-shadow"
                style="background-color: #FFFFFF;">
                <div class="text-center">
                    <img src="/assets/img/logo.jpg" class="rounded-circle w-50 h-50 img-fluid" alt="...">
                </div>
            </div>
            <div class="card-body text-center pb-1" >
                <h2>Change Password</h2>

                <div class="card-body text-center pb-1 cssanimation sequence fadeInBottom" >
                    <form [formGroup]="passwordFrom">
                        <div class="input-group">
                            <input  formControlName="oldPassword" class="form-control validation-field"
                                placeholder="Old Password" matInput [type]="showPassword ? 'text' : 'password'"
                                name="password" (input)="onChangeEvent($event)" />
                            <span class="input-group-text">
                                <mat-icon matSuffix (click)="togglePasswordVisibility()">
                                    {{showPassword?'visibility':'visibility_off'}}
                                </mat-icon>
                            </span>
                        </div>
                        <small class="text-primary"
                        *ngIf="passwordFrom.get('oldPassword').touched && this.appUserList[0]['Password'] != passwordInput">
                        Your Old Password not matched
                    </small>
                        <br>
                        <div class="input-group">
                            <input formControlName="password" class="form-control validation-field"
                                placeholder="New Password" matInput [type]="showNewPassword ? 'text' : 'password'"
                                name="password" (input)="newPasswordChange($event)" />
                            <span class="input-group-text">
                                <mat-icon matSuffix (click)="toggleNewPasswordVisibility()">
                                    {{showNewPassword?'visibility':'visibility_off'}}
                                </mat-icon>
                            </span>
                        </div>
                        <small class="text-primary"
                            *ngIf="passwordFrom.get('password').touched && passwordFrom.get('password').hasError('required')">
                            password is required
                        </small>
                        <br>
                        <div class="input-group">
                            <input formControlName="confirmPassword" class="form-control validation-field"
                                placeholder="Confirm Password" matInput [type]="showConfirmPassword ? 'text' : 'password'"
                                name="password" (input)="confirmPasswordChange($event)" />
                            <span class="input-group-text">
                                <mat-icon matSuffix (click)="toggleConfirmPasswordVisibility()">
                                    {{showConfirmPassword?'visibility':'visibility_off'}}
                                </mat-icon>
                            </span>
                        </div>
                        <small class="text-primary"
                            *ngIf="passwordFrom.get('confirmPassword').touched && passwordFrom.get('confirmPassword').hasError('required')">
                            confirm password is required
                        </small>
                        <small class="text-primary"
                            *ngIf="passwordFrom.get('confirmPassword').touched && newPasswordInput!=confirmPasswordInput">
                            password and confirm password are mismatch
                        </small>
    
                        <div class="form-group p-4">
                            <button class="form-control btn btn-primary rounded  px-3" style="color: white;"
                                class="btn btn-block bg-primary"
                                [disabled]="passwordFrom.invalid || (newPasswordInput!=confirmPasswordInput)"
                                (click)="goToSave()">save</button>
                        </div>
                    </form>
                </div>

                </div>
            </div>
    </div>

    <!-- <div class="col-xl-1 col-md-1 col-1">

    </div>

</div> -->